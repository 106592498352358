const en = {
    general: {
        subtitle: "SOLVE THE 9 CHALLENGES AND GET ACCESS TO THE EXHIBITION'S SECRET ROOM",
        play: "START",
        back: "BACK",
        forward: "NEXT"
    },
    notifications: {
        phase_validated_title: "Congrats!",
        phase_validated_description: "You have succesfully completed this phase. Keep going!",
        phase_failed_title: "Almost there...",
        phase_failed_description: "You didn't complete this phase. Try again!",
        register_ok: "You have successfully registered.",
        register_fail: "You weren't able to register. Try again.",
        phase_already_validated_title: "Hey! Go back",
        phase_already_validated_description: "You had already completed this phase.",

    },
    scan_qr: {
        title: "SCAN YOUR QR"
    },
    action_bar: {
        back: "BACK",
        validate: "CHECK",
        next: "NEXT",
    },
    register: {
        description: "Register to start playing.",
        name: "First Name",
        surname: "Last Name",
        email: "Email",
        zip_code: "Zip Code",
        terms_conditions: "I have read and accept the terms and conditions.",
        receive_information: "I want to receive commercial information.",
        access: "ENTER",
    },
    home: {
        code_access: "SECRET ROOM ACCESS CODE",
        code_access_unlocked: "CLICK TO FIND THE SECRET PASSWORD",
        code_access_date: "IN WHICH YEAR WAS CRIMS RELEASED ON THE RADIO? ANSWER: C+YEAR",
        map_locked: "SECRET ROOM MAP",
        map_unlocked: "ACCESS MAP",
        map_showed: "FIND THE SECRET LOCATION WITH THE MAP",

    },
    phase1: {
        title: "1. ANAGRAM",
        story: "Investigators need to pay attention to every detail, no matter how small it may be. Take a look at the first room of the exhibition, is there anything that catches your eye?",
        description: "Find the marked letters and complete the sentence following the color code:",
    },
    phase2: {
        title: "2. WINDOWS",
        story: "To understand a murderer you have to think like them, get inside their heads. Crimes can have different triggers: economic, religious, moral, sexual or just violence. To solve this phase, look at the 7 windows that you will find in the second room.",
        description: "Match each description with the name of the corresponding case:",
        case_1: "GRANNY ANITA",
        case_2: "ANGI",
        case_3: "ISIDRE",
        case_4: "MACHALA",
        case_5: "SONIA",
        case_6: "LA FLOR",
        case_7: "CA N'AMAT",
        case_1_description: "She worked at a popular bakery, and by taking advantage of her friendly relationship with them, she enters the victims' homes. Once inside, she distracts them by asking for a glass of water to attack them by surprise. Once dead, she steals their money and valuables.",
        case_2_description: "To maintain her lifestyle, she impersonates a co-worker to take out life insurance and loans worth thousands of euros. Two years later, she kills her to collect the contracted insurance.",
        case_3_description: "Tired of her marriage and drowned by the debts generated by her addiction to bingo, she commissions two hitmen to kill her husband to collect the life insurance. The criminals turn out to be a bit clumsy and it takes more than one attempt to end his life.",
        case_4_description: "Taking advantage of the different types of jobs he has, he watches and harasses his victims. Once helpless, he strangles and sexually assaults them.",
        case_5_description: "Six young people from Barcelona go out “hunting”, looking for a place to deposit all their rage, hatred, and aggression. They find their target sleeping in the Ciutadella Park: two homeless transsexual women.",
        case_6_description: "A husband and father in Colombia, a quiet and hard-working homosexual immigrant in Spain, and a violent ex-military man of neo-Nazi ideology in private. His mortal victim represents everything he hates about himself.",
        case_7_description: "The relationship between siblings and their partners, who live under the same roof, becomes strained until one of the couples decides to end the life of the other. Inspired by a famous TV series, they butcher the corpses and hide them in the freezer of the house they share.",
        case: "Which case does this description match?",
        t_description: "DESCRIPTION",
        window_title: "WINDOW"
    },
    phase3: {
        title: "3. SKETCHES",
        selected: "SELECTED",
        story: "The mind can play tricks on us: it is quite possible that our memories change, that faces become blurred and that we even remember things that never happened that way. Look around the room for the sketches of possible suspects, can you recognize them?",
        description: "Select the sketches of 2 of the suspects you saw in the lineup in room 3:",
    },
    phase4: {
        title: "4. INTERROGATION",
        story: "The weight of silence becomes very apparent during interrogations. People involved in cases can hide information for years and go about their lives as if nothing had happened. In this phase, we have made up a FAKE case so that you can put yourself in the role of the interviewer.",
        description: "Review the interviews of 3 people related to our case. <br>With this information, determine which statements are true:",
        interrogations: "SELECT A PERSON TO INTERVIEW THEM:",
        what_know: "WHAT DO WE KNOW?",
        person_empty: "Interview the person to know more details.",
        reconstruction: "EVENT RECONSTRUCTION",
        description_contradiction: "Which of the following statements are true?",
        plausible: "TRUE",
        contradiction: "FALSE",
        person_1: "Recepcionist",
        person_2: "Partner",
        person_3: "Colleague",
        person_3_br: "Colleague",
        statement_1_A: "- The victim and his work colleague go together to an exhibition on the evening of the events.",
        statement_1_B: "- The victim was wearing fancy clothes that evening.",
        statement_1_C: "- Finds the corpse the next morning in a dumpster near the Palau.",
        statement_2_A: "- Was the victim's partner.",
        statement_2_B: "- The last time she saw the victim was that afternoon.",
        statement_2_C: "- The victim had trouble with a female work colleague.",
        statement_3_A: "- Had an affair with the victim.",
        statement_3_B: "- Knew the victim had trouble with his partner.",
        statement_3_C: "- She leaves the exhibition on her own, not with the victim.",
        fact_1: "The receptionist finds the body the same night of the incident.",
        fact_2: "The partner says that the victim was having problems with a female co-worker.",
        fact_3: "The co-worker knew that the victim had a partner.",
        fact_4: "According to the partner, the victim slept at home that night.", 
        fact_5: "According to the receptionist, the victim was dressed up on the night of the incident.", 
        fact_6: "The partner says that the victim left work because of health problems.", 
        question_A_1: "When did you find the body?",
        question_A_2: "Did you know the victim?",
        question_A_3: "How was the victim dressed?",
        question_B_1: "What was your relationship with the victim?",
        question_B_2: "Do you know if the victim had any known enemies?",
        question_B_3: "When did you realize he was missing?",
        question_C_1: "What was your relationship with the victim?",
        question_C_2: "When was the last time you saw the victim?",
        question_C_3: "Do you know if he had any known enemies?",
        response_A_1: "In the morning, when I was going back to work to the Palau at about 8.30 a.m. I saw a foot sticking out from a bag in one of the containers nearby.",
        response_A_2: "The previous evening he came to see the exhibition with a girl. I thought they were a couple, they argued a lot but left the place together.",
        response_A_3: "They were both dressed up... He was wearing jeans and a white shirt.",
        response_B_1: "We have been together for 8 years.",
        response_B_2: "He had recently left work because he had problems with a co-worker... She was obsessed with him, she wouldn't leave him alone.",
        response_B_3: "That night he didn't come home, it wasn't like him. I was worried but since we had argued that morning I thought maybe he was with friends. The next morning I was woken up by a call from the police to tell me that they had found his lifeless body.",
        response_C_1: "We were co-workers and we were in love... he was not in love with his partner, he had promised me that night that he was going to leave her that same night.",
        response_C_2: "That afternoon we were together at the Palau Robert. After seeing the exhibition he told me that he was going back to his house and that the next day everything would be different. He wanted to leave her that very same night. After seeing the exhibition, I went back home.",
        response_C_3: "Not that I know of... he got along with everybody. The only one who caused problems was his partner... In fact, she made him quit his job because she was jealous of me.",
        interrogation_suspect: "SUSPECT",
        interrogator: "INTERVIEWER",
        suspect: "SUSPECT",
        question: "QUESTION",
        response: "ANSWER",
        questions: "QUESTIONS"

    },
    phase5: {
        title: "5. TIMELINE",
        story: "When CRIMS premiered on TV it was a complete success. The chapter about the Girl of Portbou broke all audience records. You will now find the name of 6 episodes, can you remember in which order where they released?",
        description: "Order the episodes following their TV release date. You can find the information online:",
        facts_found: "DATES FOUND",
        scan_next: "SCAN THE <br>NEXT QR",
        qr_1_title: "Brito and Picatoste",
        qr_2_title: "Machala",
        qr_3_title: "Ca n'Amat",
        qr_4_title: "Guardia Urbana",
        qr_5_title: "Iaia Anita",
        qr_6_title: "The Girl from Portbou",
        qr_1_description: "Evi Rauter leaves a note in her sister's apartment in Florence that reads: “I'm going to Siena.”",
        qr_2_description: "A neighbor in Portbou sees a girl hanging from a pine tree near the cemetery. She is not carrying any kind of identification and the case goes cold.",
        qr_3_description: "The CRIMS team takes an interest in the case and records the first episode of “The girl from Portbou”.",
        qr_4_description: "An Austrian TV channel, thanks to the collaboration of the CRIMS team, broadcasts an episode on “The girl from Portbou”. An Italian woman on vacation in Austria sees the episode and identifies the girl.",
        qr_5_description: "The CRIMS team receives a message from the Austrian TV saying that they have identified the girl: “WE FOUND THE GIRL!”",
        qr_6_description: "The CRIMS chapters about “The girl of Portbou” are broadcasted on TV3, where we can see that the family confirms the identity of Evi Rauter, a young Italian girl who had been missing for more than 32 years.",
    },
    phase6: {
        title: "6. CCTV",
        story: "Technological advances have made it possible to solve many cases, even years after they happened. In this case, you will have to keep an eye on the video surveillance cameras to identify a number of objects.",
        description: "Have you seen the following objects on the video surveillance cameras? Check the monitors to find out:",
        cameras: "CAMERAS",
        name_1: "RED NOTEBOOK",
        name_2: "GRANNY ANITA'S CAT",
        name_3: "DEXTER DVDS",
        name_4: "BRUNETTE WIG",
        name_5: "AXE",
        name_6: "FLAG",
        name_7: "FLOWERS",
        name_8: "SPORTS BAG",
        yes: "YES",
        no: "NO",

    },
    phase7: {
        title: "7. TAPPING",
        story: "Police wiretapping is an essential tool. <br>Investigators spend hours listening to conversations to find details that can lead them in the right direction. To solve this challenge, you will have to find some phones and listen to the messages.",
        description: "Find the phones hanging from the ceiling and answer the following questions based on what you hear:",
        question_1: "In an intercepted call from the Brito and Picatoste case, two men talk about going up a road, what road is it?",
        question_2: "In a conversation between Isaac and his friend, he says “Whenever I talk to you about my ____, you have to laugh or what?”. What is he talking about?",
        question_3: "How much money did Isidre's wife offer to kill her husband?",
        /*question_4: "In a conversation between Isaac and his friend, he says “Whenever I talk to you about my ____, you have to laugh or what?”. What is he talking about?",
        question_5: "In a call between Isidre's killers, one of them says that a part of his body is very swollen. What part of his body is he talking about?",*/
        response_1_A: "Tibidado",
        response_1_B: "Collserola",
        response_1_C: "Sant Cugat",
        response_1_D: "Carretera de les Aigües",
        response_2_A: "girlfriend",
        response_2_B: "mother",
        response_2_C: "house",
        response_2_D: "hobbies",
        response_3_A: "5 million pesetas",
        response_3_B: "7 million pesetas",
        response_3_C: "1 million pesetas",
        response_3_D: "700.000 pesetas",
        /*response_4_A: "house",
        response_4_B: "mother",
        response_4_C: "girlfriend",
        response_4_D: "hobbies",
        response_5_A: "Hand",
        response_5_B: "Lips",
        response_5_C: "Knee",
        response_5_D: "Eyes",*/
    },
    phase8: {
        title: "8. DECIPHER",
        story: "Brito and Picatoste escaped from a prison in Lleida, leaving a trail of blood all over Catalonia. They communicated with different codes that, in theory, they had to memorize and burn. Police officers found their secret code and were able to decipher their messages.",
        description: "Find the 4 QR codes placed around the room to get the key to the codes of the fugitives. <br>When you find them, use them to decipher the next message:",
        scan_qr: "SCAN THE QR TO DECIPHER THE CODE"
    },
    phase9: {
        title: "9. STATISTICS",
        story: "Although in CRIMS we show you the darker side of our society, the crime figures in Catalonia are very low. Find the screen with the crime map of Catalonia to solve the following enigma.",
        description: "Check the touch screen or access the following link to answer the following questions:",
        question_1: "How many “temptatives d’assassinat” (attempted murders) were there in Catalonia in 2019?",
        question_2: "2 of these attempted murders happened in the same month. Which month was it?",
        question_3: "How many “temptatives d’assassinat” (attempted murders) were there in Catalonia in 2020?",
        question_4: "How many “assasinats consumats” (consummated murders) were there in Catalonia in 2020?",
        access_web: "ACCESS WEBSITE",
    },
    cookies: {
        title: "Cookies",
        text_accept: "This website uses technical cookies. If you want to know more, ",
        click_here: "click here.",
        btn_accept: "Ok",
        text: "Our Cookies Policy is intended to inform you clearly and completely about the operation and purpose of the cookies used on our Website." +
            " If you would like more information about cookies, you can send an e-mail to zoopa{'@'}zoopa.es.<br><br>What is a cookie?<br><br>" +
            "A cookie is a file that is downloaded to your terminal equipment/device (computer or cell phone) in order to store data that can be updated and retrieved by the entity responsible for its installation. Our website uses 'cookies' in order to collect information about its operation and use by its users.<br><br>Purpose<br><br>Cookies can collect information about your visits to our Web Site, the frequency and duration of your visits, the pages viewed and the time you have been on our Web Site. We inform you that we may use cookies for the following purposes:<br>- Provide you with a better experience in the use of the same.<br><br>Cookies used on the web<br><br>Our Web Site uses its own cookies for different purposes, specifically:<br><br>Own Cookies<br><br>They are sent to your computer and managed exclusively by us for the better functioning of the Web Site. These cookies remain in your browser, allowing us to identify you as a recurring user of the Website and to personalize its content in order to offer you content tailored to your preferences.<br><br>Cookies used on the website<br><br>Technical Cookies<br><br>Technical cookies are those essential for the operation of the website, such as managing authentication or maintaining a session of a registered user on the website. The deactivation of these cookies may prevent the proper functioning of the site.<br><br>Consent<br><br>"
            + "By browsing and continuing on our Website you are not consenting to the use of the cookies listed above, under the conditions contained in this Cookies Policy. It is necessary to expressly accept or reject them. We inform you that, since cookies are not necessary to browse our Web Site, you can block or disable them through your browser settings. If you block or disable them, you will still be able to use our Website, although the use of some of its services may be limited and therefore your experience on our Website less satisfactory. If you wish to revoke your informed consent regarding our cookies policy, you must delete them on your device through the settings of your Internet browsers. These are the links to the different browsers that inform about cookies: Firefox Chrome Explorer Safari Opera We recommend that you review this policy each time you access our Website in order to be properly informed about possible changes to it.",
    }
}

module.exports = {
    en
}

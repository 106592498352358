import axios from 'axios'

const API_URL = process.env.VUE_APP_API_URL


const axiosToken = ({ url = url, body = {}, method = method, content_type = 'application/json' }) => {

    try {
        if (method == "delete") {
            return axios.delete(API_URL + url, {
                headers: { authorization: "Token " + localStorage.user },
                data: body
            })
        }
        else if (method == "get") {
            return axios.get(API_URL + url, {
                headers: { authorization: "Token " + localStorage.user },
                params: body
            })
        }
        return axios({
            method: method,
            url: API_URL + url,
            headers: { authorization: "Token " + localStorage.user, "content-type": content_type },
            data: body
        })
    }
    catch (error) {
        console.log(error)
    }
}

export { axiosToken }
<template>
    <div class="text-center justify-center items-center pb-2">
        <div class="text-left text-xl items-start justify-start">
            <PhaseHeader :title="$t('phase1.title')" :description="$t('phase1.description')" />
        </div>
        <div class="font-phase-description" v-if="language == 'en'">
            Everything y<span class="bg-green-500">o</span>u <span class="bg-yellow-500">w</span>ill se<span
                class="bg-blue-500"
                >e</span
            >
            in this <span class="bg-red-300">e</span>xhibition has happened. The fa<span class="bg-red-300">c</span>ts,
            names and places are real. Some <span class="bg-red-300">s</span>cenes and e<span class="bg-blue-500"
                >l</span
            >ements are dra<span class="bg-red-300">m</span>atized, but t<span class="bg-green-500">h</span>eir content
            is based on documents or comes from witne<span class="bg-green-500">s</span>ses who
            <span class="bg-green-500">w</span>ere on the scen<span class="bg-yellow-500">e</span>. In some expe<span
                class="bg-red-300"
                >r</span
            >iences, this exhibition could hurt people' sensitivities. We show real cr<span class="bg-red-300">i</span
            >mes. <span class="bg-blue-500">R</span>eality and death h<span class="bg-blue-500">a</span>ve no
            sensitivities.
            <input
                @blur="storeData(0)"
                @keyup="controlMaxLength($event, 2)"
                class="input-0-en"
                maxlength="2"
                v-model="word_0"
            />
            <input
                @blur="storeData(1)"
                @keyup="controlMaxLength($event, 4)"
                class="input-1-en"
                maxlength="4"
                v-model="word_1"
            />
            <input
                @blur="storeData(2)"
                @keyup="controlMaxLength($event, 4)"
                class="input-2-en"
                maxlength="4"
                v-model="word_2"
            />
            <input
                @blur="storeData(3)"
                @keyup="controlMaxLength($event, 6)"
                class="input-3-en"
                maxlength="6"
                v-model="word_3"
            />
        </div>
        <div v-else-if="language == 'es'" class="font-phase-description">
            Todo lo que v<span class="bg-blue-500">e</span>réis en est<span class="bg-green-500">a</span> expo<span
                class="bg-green-500"
                >s</span
            >ición ha pa<span class="bg-red-300">s</span>ado. Los hechos, los no<span class="bg-green-500">m</span>bres
            y los luga<span class="bg-red-300">r</span>es son r<span class="bg-blue-500">e</span>ales. Algunas
            escen<span class="bg-red-300">a</span>s y elementos están dra<span class="bg-blue-500">m</span>atizados,
            pero su contenido <span class="bg-blue-500">c</span>onsta en docu<span class="bg-green-500">m</span>entos
            <span class="bg-green-500">o</span> sale de testigos que e<span class="bg-green-500">s</span>taban en el
            lugar de los h<span class="bg-red-300">e</span>chos. En algunas experiencias, es<span class="bg-green-500"
                >t</span
            >a exposición podría heri<span class="bg-green-500">r</span> s<span class="bg-red-300">e</span
            >nsibilidade<span class="bg-blue-500">s</span>. Mostramos cr<span class="bg-blue-500">í</span>menes rea<span
                class="bg-red-300"
                >l</span
            >es. La <span class="bg-blue-500">r</span>ealidad y la muerte n<span class="bg-green-500">o</span> tienen
            se<span class="bg-blue-500">n</span>sibilidades.
            <input
                @blur="storeData(1)"
                @keyup="controlMaxLength($event, 9)"
                class="input-1-es"
                maxlength="9"
                v-model="word_1"
            />
            <input
                @blur="storeData(2)"
                @keyup="controlMaxLength($event, 8)"
                class="input-2-es"
                maxlength="8"
                v-model="word_2"
            />
            <input
                @blur="storeData(3)"
                @keyup="controlMaxLength($event, 6)"
                class="input-3-es"
                maxlength="6"
                v-model="word_3"
            />
        </div>
        <div v-else class="font-phase-description">
            Tot el que veu<span class="bg-blue-500">r</span>eu en aques<span class="bg-green-500">t</span>a exposició
            h<span class="bg-red-300">a</span> passat. Els fet<span class="bg-blue-500">s</span>, el<span
                class="bg-red-300"
                >s</span
            >
            no<span class="bg-green-500">m</span>s i els ll<span class="bg-green-500">o</span>cs són reals. Algunes
            escenes i elements <span class="bg-red-300">e</span>stan dra<span class="bg-blue-500">m</span>atitzats, però
            el seu contingut con<span class="bg-green-500">s</span>ta en do<span class="bg-blue-500">c</span>uments o
            surt de testi<span class="bg-green-500">m</span>onis que eren al lloc dels fets. En algunes experiències,
            aquesta exposició podria feri<span class="bg-green-500">r</span> sensibi<span class="bg-red-300">l</span
            >itats. Mostrem crims reals. La realitat <span class="bg-blue-500">i</span> la mo<span class="bg-red-300"
                >r</span
            >t no en ten<span class="bg-green-500">e</span>n de sensibilitats.
            <input
                @blur="storeData(1)"
                @keyup="controlMaxLength($event, 7)"
                class="input-1"
                maxlength="7"
                v-model="word_1"
            />
            <input
                @blur="storeData(2)"
                @keyup="controlMaxLength($event, 5)"
                class="input-2"
                maxlength="5"
                v-model="word_2"
            />
            <input
                @blur="storeData(3)"
                @keyup="controlMaxLength($event, 5)"
                class="input-3"
                maxlength="5"
                v-model="word_3"
            />
        </div>
    </div>
</template>

<script>
import PhaseHeader from "@/components/PhaseHeader.vue";
import { useStore } from "vuex";
import { ref, onMounted } from "vue";
export default {
    props: {},
    components: { PhaseHeader },

    setup(props) {
        const store = useStore();
        const word_0 = ref(store.state.storePhasesData.phase1Data.word_0);
        const word_1 = ref(store.state.storePhasesData.phase1Data.word_1);
        const word_2 = ref(store.state.storePhasesData.phase1Data.word_2);
        const word_3 = ref(store.state.storePhasesData.phase1Data.word_3);
        const language = ref("ca");
        const number = 2;
        onMounted(() => {
            language.value = localStorage.language;
        });

        const controlMaxLength = (event, max) => {
            if (event.target.value.length > max) {
                event.target.value = event.target.value.substr(0, max);
            }
        };

        const storeData = (num_answer) => {
            console.log(num_answer);
            console.log(word_1.value);
            store.commit("phase1SetResults", {
                word_0: word_0.value,
                word_1: word_1.value,
                word_2: word_2.value,
                word_3: word_3.value,
            });
        };
        return { storeData, word_0, word_1, word_2, word_3, language, number, controlMaxLength };
    },
};
</script>

<style lang="scss" scoped>
$char-w: 1ch;
$gap: 0.2 * $char-w;
$n-char: 7;
$in-w: $n-char * ($char-w + $gap);

input {
    display: block;
    margin: 0.5em auto;
    border: none;
    padding: 0;

    font: 5ch droid sans mono, consolas, monospace;
    letter-spacing: $gap;
    @apply uppercase;
}
.input-0-en {
    $n-char: 2;
    $in-w: $n-char * ($char-w + $gap);

    width: $in-w;
    background: repeating-linear-gradient(
            90deg,
            rgb(255, 208, 0) 0,
            rgb(255, 208, 0) $char-w,
            transparent 0,
            transparent $char-w + $gap
        )
        0 100% / #{$in-w - $gap} 5px no-repeat;
    color: rgb(255, 208, 0);

    &:focus {
        outline: none;
    }
}
.input-1-en {
    $n-char: 4;
    $in-w: $n-char * ($char-w + $gap);

    width: $in-w;
    background: repeating-linear-gradient(
            90deg,
            lightgreen 0,
            lightgreen $char-w,
            transparent 0,
            transparent $char-w + $gap
        )
        0 100% / #{$in-w - $gap} 5px no-repeat;
    color: lightgreen;

    &:focus {
        outline: none;
    }
}
.input-2-en {
    $n-char: 4;
    $in-w: $n-char * ($char-w + $gap);

    width: $in-w;
    background: repeating-linear-gradient(
            90deg,
            rgb(87, 165, 230) 0,
            rgb(87, 165, 230) $char-w,
            transparent 0,
            transparent $char-w + $gap
        )
        0 100% / #{$in-w - $gap} 5px no-repeat;
    color: rgb(87, 165, 230);

    &:focus {
        outline: none;
    }
}
.input-3-en {
    $n-char: 6;
    $in-w: $n-char * ($char-w + $gap);

    width: $in-w;
    background: repeating-linear-gradient(
            90deg,
            lightcoral 0,
            lightcoral $char-w,
            transparent 0,
            transparent $char-w + $gap
        )
        0 100% / #{$in-w - $gap} 5px no-repeat;
    color: lightcoral;

    &:focus {
        outline: none;
    }
}
.input-1 {
    $n-char: 7;
    $in-w: $n-char * ($char-w + $gap);

    width: $in-w;
    background: repeating-linear-gradient(
            90deg,
            lightgreen 0,
            lightgreen $char-w,
            transparent 0,
            transparent $char-w + $gap
        )
        0 100% / #{$in-w - $gap} 5px no-repeat;
    color: lightgreen;

    &:focus {
        outline: none;
    }
}
.input-2 {
    $n-char: 5;
    $in-w: $n-char * ($char-w + $gap);

    width: $in-w;
    background: repeating-linear-gradient(
            90deg,
            rgb(87, 165, 230) 0,
            rgb(87, 165, 230) $char-w,
            transparent 0,
            transparent $char-w + $gap
        )
        0 100% / #{$in-w - $gap} 5px no-repeat;
    color: rgb(87, 165, 230);

    &:focus {
        outline: none;
    }
}
.input-3 {
    $n-char: 5;
    $in-w: $n-char * ($char-w + $gap);

    width: $in-w;
    background: repeating-linear-gradient(
            90deg,
            lightcoral 0,
            lightcoral $char-w,
            transparent 0,
            transparent $char-w + $gap
        )
        0 100% / #{$in-w - $gap} 5px no-repeat;
    color: lightcoral;

    &:focus {
        outline: none;
    }
}
.input-1-es {
    $n-char: 9;
    $in-w: $n-char * ($char-w + $gap);

    width: $in-w;
    background: repeating-linear-gradient(
            90deg,
            lightgreen 0,
            lightgreen $char-w,
            transparent 0,
            transparent $char-w + $gap
        )
        0 100% / #{$in-w - $gap} 5px no-repeat;
    color: lightgreen;

    &:focus {
        outline: none;
    }
}
.input-2-es {
    $n-char: 8;
    $in-w: $n-char * ($char-w + $gap);

    width: $in-w;
    background: repeating-linear-gradient(
            90deg,
            rgb(87, 165, 230) 0,
            rgb(87, 165, 230) $char-w,
            transparent 0,
            transparent $char-w + $gap
        )
        0 100% / #{$in-w - $gap} 5px no-repeat;
    color: rgb(87, 165, 230);

    &:focus {
        outline: none;
    }
}
.input-3-es {
    $n-char: 6;
    $in-w: $n-char * ($char-w + $gap);

    width: $in-w;
    background: repeating-linear-gradient(
            90deg,
            lightcoral 0,
            lightcoral $char-w,
            transparent 0,
            transparent $char-w + $gap
        )
        0 100% / #{$in-w - $gap} 5px no-repeat;
    color: lightcoral;

    &:focus {
        outline: none;
    }
}
.font-phase-description {
    font-size: 20px;
}
</style>

<template></template>

<script>
// @ is an alias to /src

import { useRouter } from "vue-router";
import { onMounted } from "vue";
import { useStore } from "vuex";

export default {
    name: "SuccessView",
    components: {},
    setup(props) {
        const router = useRouter();
        const store = useStore();

        const solveAllPhases = () => {
            store.commit("setPhasesValidated", { phase_id: 1, validated: true });
            store.commit("setPhasesValidated", { phase_id: 2, validated: true });
            store.commit("setPhasesValidated", { phase_id: 3, validated: true });
            store.commit("setPhasesValidated", { phase_id: 4, validated: true });
            store.commit("setPhasesValidated", { phase_id: 5, validated: true });
            store.commit("setPhasesValidated", { phase_id: 6, validated: true });
            store.commit("setPhasesValidated", { phase_id: 7, validated: true });
            store.commit("setPhasesValidated", { phase_id: 8, validated: true });
            store.commit("setPhasesValidated", { phase_id: 9, validated: true });
        };

        onMounted(() => {
            solveAllPhases();
            router.push("/");
        });

        return {};
    },
};
</script>

<template>
    <div class="game-view">
        <Game :phase="phase" />
    </div>
</template>

<script>
import { useRoute, useRouter } from "vue-router";
import { ref, watch, onMounted } from "vue";
import Game from "@/components/Game.vue";
// @ is an alias to /src

export default {
    name: "GameView",
    props: {},
    components: { Game },
    setup(props) {
        const route = useRoute();
        const router = useRouter();

        const phase = ref(route.query.phase);

        watch(
            () => route.query.phase,
            () => (phase.value = route.query.phase)
        );
        onMounted(() => {
            if (!localStorage.uuid) {
                router.push("/language");
            }
        });

        return {
            phase,
        };
    },
};
</script>
<style scoped></style>

<template>
    <div class="text-center justify-center items-center mb-20">
        <div class="text-left text-xl items-start justify-start">
            <PhaseHeader :title="$t('phase8.title')" :description="$t('phase8.description')" />
        </div>
        <div class="flex flex-col">
            <div class="flex flex-col border-solid border-3 bg-silver-dark/30 border-silver-dark">
                <img :src="require('@/assets/images/codi.png')" alt="" class="w-full" />
                <div class="flex flex-row flex-wrap gap-1 my-2">
                    <!--<input
                            v-for="(letter, index) in word.word"
                            :key="word.id + '-' + index"
                            @keyup.prevent="storeData($event)"
                            :class="'input'"
                            class="font-title-btn"
                            :maxlength="1"
                            v-model="word.word[index]"
                            autocomplete="off"
                            name="password"
                        />-->
                    <input
                        v-for="(word, index) in words"
                        :key="word"
                        @blur.prevent="storeData($event, index)"
                        @keyup.prevent="nextWord(index)"
                        :class="'input-' + index"
                        class="font-title-btn"
                        :maxlength="word.maxlength"
                        v-model="word.word"
                        autocomplete="off"
                        :name="'word_' + index"
                    />
                </div>
            </div>
        </div>
        <div class="flex flex-col">
            <div
                class="grid grid-cols-2 place-items-center justify-items-center border-solid border-3 bg-silver-dark/30 border-silver-dark mt-4 py-2"
                :class="$store.state.storePhasesData.phase8Data.qr_scanned.length != 4 ? 'mb-14' : ''"
            >
                <div class="flex flex-col font-title-btn w-full h-full justify-center mb-2">
                    <img v-if="qr_scanned.includes(1)" :src="require('@/assets/images/codi_1.png')" alt="" />
                    <img v-else :src="require('@/assets/images/question_mark.png')" alt="" />
                </div>
                <div class="flex flex-col font-title-btn w-full h-full justify-center mb-2">
                    <img v-if="qr_scanned.includes(2)" :src="require('@/assets/images/codi_2.png')" alt="" />
                    <img v-else :src="require('@/assets/images/question_mark.png')" alt="" />
                </div>
                <div class="flex flex-col font-title-btn w-full h-full justify-center">
                    <img v-if="qr_scanned.includes(3)" :src="require('@/assets/images/codi_3.png')" alt="" />
                    <img v-else :src="require('@/assets/images/question_mark.png')" alt="" />
                </div>
                <div class="flex flex-col font-title-btn w-full h-full justify-center">
                    <img v-if="qr_scanned.includes(4)" :src="require('@/assets/images/codi_4.png')" alt="" />
                    <img v-else :src="require('@/assets/images/question_mark.png')" alt="" />
                </div>
            </div>
        </div>
        <div
            v-if="$store.state.storePhasesData.phase8Data.qr_scanned.length != 4"
            class="flex flex-col justify-center items-center fixed mb-16 bg-blue-dark w-full left-0 bottom-0"
        >
            <div @click="scanQR" class="flex flex-row items-center justify-center gap-4 button p-1 px-6 mt-4 w-8/12">
                <div class="flex font-title-btn qr-btn w-9/12">{{ $t("phase8.scan_qr") }}</div>
                <inline-svg
                    :src="require('@/assets/icons/camera-solid.svg')"
                    alt="Validar prova"
                    class="fill-silver-light w-2/12 flex"
                />
            </div>
        </div>
    </div>
</template>

<script>
import PhaseHeader from "@/components/PhaseHeader.vue";
import { useRouter } from "vue-router";
import { ref } from "vue";
import { useStore } from "vuex";
export default {
    props: {},
    components: { PhaseHeader },

    setup(props) {
        const router = useRouter();
        const store = useStore();
        const qr_scanned = ref(store.state.storePhasesData.phase8Data.qr_scanned);
        /*const words = ref([
            { id: 0, word: ["", ""] },
            { id: 1, word: ["", "", "", "", "", "", ""] },
            { id: 2, word: ["", "", ""] },
            { id: 3, word: ["", "", "", "", "", "", ""] },
            { id: 4, word: ["", ""] },
            { id: 5, word: ["", ""] },
            { id: 6, word: ["", "", "", ""] },
        ]);*/
        const words = ref([
            { id: 0, word: "", maxlength: 2 },
            { id: 1, word: "", maxlength: 7 },
            { id: 2, word: "", maxlength: 3 },
            { id: 3, word: "", maxlength: 7 },
            { id: 4, word: "", maxlength: 2 },
            { id: 5, word: "", maxlength: 2 },
            { id: 6, word: "", maxlength: 4 },
        ]);

        const nextWord = (word) => {
            if (event.target.value.length == words.value[word].maxlength && word != 6) {
                console.log("here");
                event.target.nextElementSibling.focus();
            }
        };
        const storeData = (event, word) => {
            //console.log(event.target.value);
            store.commit("phase8SetResults", words);

            //console.log(words);

            /*setTimeout(() => {
                if (event.which != 8) {
                    if (event.target.nextElementSibling) {
                        event.target.nextElementSibling.focus();
                    } else if (event.target.parentElement.nextElementSibling) {
                        if (event.target.parentElement.nextElementSibling.firstElementChild) {
                            event.target.parentElement.nextElementSibling.firstElementChild.focus();
                        }
                    } /*
                } else if (event.target.previousElementSibling) {
                    event.target.previousElementSibling.focus();
                } else if (event.target.parentElement.previousElementSibling) {
                    event.target.parentElement.previousElementSibling.lastElementChild.focus();

                }
            }, "250");*/
        };

        const scanQR = () => {
            router.push({ name: "scanQR", query: { phase: 8 } });
        };

        return { scanQR, words, storeData, qr_scanned, nextWord };
    },
};
</script>

<style lang="scss" scoped>
.button {
    cursor: pointer;

    @apply bg-silver-dark rounded-md;
}

.qr-btn {
    font-size: 12px;
}

$char-w: 1ch;
$gap: 0.2 * $char-w;

input {
    display: block;
    margin: 0.2em auto;
    border: none;
    padding: 0;

    font: 2.5ch droid sans mono, consolas, monospace;
    letter-spacing: $gap;

    @apply uppercase text-silver-light;

    &:focus {
        outline: none;
    }
}

.input-0 {
    $n-char: 2;
    $in-w: $n-char * ($char-w + $gap);

    width: $in-w;
    margin-left: 1.25em;
    background: repeating-linear-gradient(
            90deg,
            rgb(217 217 217 / var(--tw-text-opacity)) 0,
            rgb(217 217 217 / var(--tw-text-opacity)) $char-w,
            transparent 0,
            transparent $char-w + $gap
        )
        0 100% / #{$in-w - $gap} 3px no-repeat;
}

.input-1 {
    $n-char: 7;
    $in-w: $n-char * ($char-w + $gap);

    width: $in-w;
    background: repeating-linear-gradient(
            90deg,
            rgb(217 217 217 / var(--tw-text-opacity)) 0,
            rgb(217 217 217 / var(--tw-text-opacity)) $char-w,
            transparent 0,
            transparent $char-w + $gap
        )
        0 100% / #{$in-w - $gap} 3px no-repeat;
}

.input-2 {
    $n-char: 3;
    $in-w: $n-char * ($char-w + $gap);

    margin-right: 1.25em;
    width: $in-w;
    background: repeating-linear-gradient(
            90deg,
            rgb(217 217 217 / var(--tw-text-opacity)) 0,
            rgb(217 217 217 / var(--tw-text-opacity)) $char-w,
            transparent 0,
            transparent $char-w + $gap
        )
        0 100% / #{$in-w - $gap} 3px no-repeat;
}

.input-3 {
    $n-char: 7;
    $in-w: $n-char * ($char-w + $gap);

    width: $in-w;
    background: repeating-linear-gradient(
            90deg,
            rgb(217 217 217 / var(--tw-text-opacity)) 0,
            rgb(217 217 217 / var(--tw-text-opacity)) $char-w,
            transparent 0,
            transparent $char-w + $gap
        )
        0 100% / #{$in-w - $gap} 3px no-repeat;
}

.input-4 {
    $n-char: 2;
    $in-w: $n-char * ($char-w + $gap);

    width: $in-w;
    background: repeating-linear-gradient(
            90deg,
            rgb(217 217 217 / var(--tw-text-opacity)) 0,
            rgb(217 217 217 / var(--tw-text-opacity)) $char-w,
            transparent 0,
            transparent $char-w + $gap
        )
        0 100% / #{$in-w - $gap} 3px no-repeat;
}

.input-5 {
    $n-char: 2;
    $in-w: $n-char * ($char-w + $gap);

    width: $in-w;
    background: repeating-linear-gradient(
            90deg,
            rgb(217 217 217 / var(--tw-text-opacity)) 0,
            rgb(217 217 217 / var(--tw-text-opacity)) $char-w,
            transparent 0,
            transparent $char-w + $gap
        )
        0 100% / #{$in-w - $gap} 3px no-repeat;
}

.input-6 {
    $n-char: 4;
    $in-w: $n-char * ($char-w + $gap);

    width: $in-w;
    background: repeating-linear-gradient(
            90deg,
            rgb(217 217 217 / var(--tw-text-opacity)) 0,
            rgb(217 217 217 / var(--tw-text-opacity)) $char-w,
            transparent 0,
            transparent $char-w + $gap
        )
        0 100% / #{$in-w - $gap} 3px no-repeat;
}
</style>

<template></template>

<script>
import { onMounted } from "vue";
import { useI18n } from "vue-i18n";

export default {
    setup() {
        onMounted(() => {
            const { t, locale, fallbackLocale, messages } = useI18n();
            locale.value = localStorage.getItem("language") ? localStorage.getItem("language") : "ca";
        });
    },
};
</script>

<style></style>

<template>
    <div class="flex flex-col gap-12 text-center mt-8 justify-center items-center text-white content">
        <img src="../assets/images/titol_crims.png" alt="" class="w-9/12" />
        <div class="flex flex-col items-center justify-center" @click="setLanguage('ca')">
            <div
                class="flex items-center justify-center font-title-btn text-2xl mb-1 button w-64 border-2 border-silver-light border-solid"
            >
                <span>CATALÀ</span>
            </div>
        </div>
        <div class="flex flex-col items-center justify-center" @click="setLanguage('es')">
            <div
                class="flex items-center justify-center font-title-btn text-2xl mb-1 button w-64 border-2 border-silver-light border-solid"
            >
                <span>CASTELLANO</span>
            </div>
        </div>
        <div class="flex flex-col items-center justify-center" @click="setLanguage('en')">
            <div
                class="flex items-center justify-center font-title-btn text-2xl mb-1 button w-64 border-2 border-silver-light border-solid"
            >
                <span>ENGLISH</span>
            </div>
        </div>
    </div>
</template>

<script>
import "/node_modules/flag-icons/css/flag-icons.min.css";
import { useRouter, useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import { axiosToken } from "@/libs/http.js";

export default {
    props: {},
    components: {},

    setup(props) {
        const route = useRoute();
        const { t, locale, fallbackLocale, messages } = useI18n();

        const setLanguage = (language) => {
            const uuid = localStorage.uuid ? localStorage.uuid : "";
            axiosToken({
                method: "put",
                url: "register",
                body: {
                    uuid: uuid,
                    language: language,
                },
            }).then((response) => {
                /*if (response.data.success) {
                    notify(
                        {
                            group: "success",
                            title: t("notifications.register_ok"),
                        },
                        4000
                    );
                } else {
                    notify(
                        {
                            group: "error",
                            title: t("notifications.register_fail"),
                        },
                        4000
                    );
                }*/
                localStorage.language = language;
                locale.value = language;
                localStorage.uuid = response.data.uuid;
                router.push("/");
            });
        };

        const router = useRouter();

        return { setLanguage };
    },
};
</script>
<style scoped>
.button {
    border-radius: 50px;
    box-shadow: 0 5px 5px #000;
    border: none;
    @apply bg-silver-dark cursor-pointer px-2 py-4 h-16;
}
</style>

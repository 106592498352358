import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index.js'
import '@/assets/css/main.css'
import '@/assets/sass/app.scss'
import VueQrcodeReader from "vue3-qrcode-reader"
import { i18n } from './i18n'
import Notifications from 'notiwind'

const app = createApp({
    extends: App,
    beforeMount() {
        this.$store.commit('recoverStoreGameData');
    },
    /*    beforeUnmount() {
            this.$store.commit('saveStore');
        },*/
}).use(store).use(router).use(i18n).use(Notifications).use(VueQrcodeReader)

app.config.globalProperties.$filters = {
    formatDate(value) {
        const date = new Date(value)
        return moment(date).format('DD/MM/YYYY HH:mm')
    },
    formatDateDay(value) {
        const date = new Date(value)
        return moment(date).format('DD/MM/YYYY')
    }
    ,
    formatDateHour(value) {
        const date = new Date(value)
        return moment(date).format('HH:mm ')
    }
}

import InlineSvg from 'vue-inline-svg';
app.component('inline-svg', InlineSvg);

app.mount('#app');
<template></template>

<script>
// @ is an alias to /src

import { useRouter } from "vue-router";
import { onMounted } from "vue";

export default {
    name: "SuccessView",
    components: {},
    setup(props) {
        const router = useRouter();

        onMounted(() => {
            localStorage.register_success = true;
            router.push("/language");
        });

        return {};
    },
};
</script>

const url = window.location.pathname


const phase4Data = {
    statements: {},
    persons: {}

}

const phase4Mutations = {
    phase4SetResults(state, data) {
        state.phase4Data.statements[data.statement_id] = data.contradiction;
    },
    phase4SetPersonCompleted(state, data) {
        if (data.questions.value.length == data.questions.value.filter((question) => question.completed == 1).length) {
            state.phase4Data.persons[data.person_id] = true;
        }
    }

}

const phase4DataResults = ({ commit, state, rootState }, data) => {
    commit("phase4SetResults", data.val())
}

export { phase4Data, phase4DataResults, phase4Mutations }
const url = window.location.pathname


const phase2Data = {
    answers: {}
}

const phase2Mutations = {
    phase2SetResults(state, data) {
        state.phase2Data.answers[data.person_id] = data.case_id
    }
}

const phase2DataResults = ({ commit, state, rootState }, data) => {
    commit("phase2SetResults", data.val())
}

export { phase2Data, phase2DataResults, phase2Mutations }
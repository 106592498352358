<template>
    <div class="flex flex-col rounded-md border-solid border-4 bg-silver-dark/30 border-silver-dark w-full">
        <div class="flex flex-col items-center mx-4 text-center">
            <div class="flex flex-row gap-4 items-center mt-2 text-xl">
                <div class="font-title-btn text-sm">{{ fact.title }}</div>
            </div>
            <hr v-if="description" class="mb-1 w-11/12 h-1 bg-silver-dark rounded-xl border-none" />
            <div v-if="description" class="flex flex-row items-center text-lg text-justify">
                <div class="font-content">{{ fact.description }}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        fact: { type: Object, default: {} },
        description: { type: Boolean, default: false },
    },
    components: {},

    setup(props) {
        return {};
    },
};
</script>

<style lang="scss" scoped>
select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-image: url("@/assets/icons/sort-down-solid.svg");
    background-repeat: no-repeat, repeat;
    background-position: right 0.7em top 0, 0 0;
    background-size: 0.65em auto, 100%;
    @apply bg-silver-light;
}
.button {
    cursor: pointer;

    @apply bg-silver-dark rounded-md;
}
</style>

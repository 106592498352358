var wait_time_start = 20000
var wait_time_end = 10000
var wait_start_game = 20000


var speed = 1000
var i = 0
var finish = 0
var last_ms = 0
let stop_ = 0
var counter = 0
var fntext, fnid
var timertype = ""
var total_text;
var total_letter;

const typeWriter = (id_, time) => {
    console.log("START")
    total_text = document.querySelector('#' + id_).innerHTML
    document.querySelector('[id=' + id_ + ']').innerHTML = ""
    total_letter = (total_text.length)
    speed = (time / total_letter) - 2
    i = 0
    total_text = total_text.replace('&nbsp;', ' ')
    total_text = total_text.replace('&nbsp;', ' ')

    if (finish == 1) {
        stop_ = 1

    }
    finish = 1
    stop_ = 0
    counter = 0
    fnid = id_
    fntext = total_text
    last_ms = speed
    clearInterval(timertype);
    timertype = setInterval(function () {
        if (counter <= fntext.length) {
            // console.log(speed,i,fntext.substring(0, 20))
            if (fntext.charAt(counter) == "<") {
                document.querySelector('[id=' + fnid + ']').insertAdjacentHTML("beforeend", fntext.slice(counter, counter + 4));
                counter = counter + 3
            } else {
                document.querySelector('[id=' + fnid + ']').insertAdjacentHTML("beforeend", fntext.charAt(counter));
            }
            counter++;
        }

    }, speed);
}

export default typeWriter
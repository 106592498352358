<template>
    <div class="text-center justify-center items-center mx-4 my-4">
        <Phase1 v-if="phase == 1" />
        <Phase2 v-if="phase == 2" />
        <Phase3 v-if="phase == 3" />
        <Phase4 v-if="phase == 4" />
        <Phase5 v-if="phase == 5" />
        <Phase6 v-if="phase == 6" />
        <Phase7 v-if="phase == 7" />
        <Phase8 v-if="phase == 8" />
        <Phase9 v-if="phase == 9" />
        <ActionBar />
    </div>
</template>

<script>
import Phase1 from "@/components/phases/Phase_1.vue";
import Phase2 from "@/components/phases/Phase_2.vue";
import Phase3 from "@/components/phases/Phase_3.vue";
import Phase4 from "@/components/phases/Phase_4.vue";
import Phase5 from "@/components/phases/Phase_5.vue";
import Phase6 from "@/components/phases/Phase_6.vue";
import Phase7 from "@/components/phases/Phase_7.vue";
import Phase8 from "@/components/phases/Phase_8.vue";
import Phase9 from "@/components/phases/Phase_9.vue";
import ActionBar from "@/components/ActionBar.vue";
import { ref, onUpdated } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";

export default {
    props: {
        phase: { type: [Number, String], default: 0 },
    },
    components: { Phase1, Phase2, Phase3, Phase4, Phase5, Phase6, Phase7, Phase8, Phase9, ActionBar },

    setup(props) {
        const store = useStore();
        const router = useRouter();
        onUpdated(() => {
            checkPhaseBlocked();
        });

        const checkPhaseBlocked = () => {
            if (
                (!store.state.storeGameData.phasesValidated[props.phase - 2] ||
                    typeof store.state.storeGameData.phasesValidated[props.phase - 2] === "undefined") &&
                props.phase != 1
            ) {
                router.push("/");
            }
        };
        return {};
    },
};
</script>

<style scoped></style>

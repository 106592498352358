<template>
    <div class="story-view">
        <Story :phase="phase" />
    </div>
</template>

<script>
import { useRoute, useRouter } from "vue-router";
import { ref, watch, onMounted } from "vue";
import Story from "@/components/Story.vue";
// @ is an alias to /src

export default {
    name: "StoryView",
    props: {},
    components: { Story },
    setup(props) {
        const route = useRoute();

        const phase = ref(route.query.phase);
        const router = useRouter();

        onMounted(() => {
            if (!localStorage.uuid) {
                router.push("/language");
            }
        });
        watch(
            () => route.query.phase,
            () => (phase.value = route.query.phase)
        );

        return {
            phase,
        };
    },
};
</script>
<style scoped></style>

<template>
    <div class="text-center justify-center items-center mx-4 my-4">
        <img src="../assets/images/titol_crims.png" alt="" class="w-7/12" />
        <h3 class="font-cta text-lg">{{ $t("general.subtitle") }}</h3>
        <hr class="mb-4 h-1 bg-silver-light rounded-xl border-none" />
        <div v-if="!$store.state.storeGameData.mapOpened" class="grid grid-cols-3 place-items-center gap-4 mb-8">
            <div
                @click="phaseSelected(phase.phase)"
                class="button-number flex flex-row"
                :class="phase.disabled ? 'disabled' : ''"
                v-for="phase in phases"
                :key="phase"
            >
                <inline-svg
                    v-if="phase.disabled"
                    :src="require('@/assets/icons/lock-solid.svg')"
                    alt="Mapa"
                    class="w-12 h-10 fill-silver-light"
                />
                <div class="flex flex-row relative w-full items-center justify-center" v-else>
                    <inline-svg
                        :src="require('@/assets/icons/check-circle-solid.svg')"
                        v-if="phase.completed"
                        class="icon-completed"
                    />
                    <h1 class="number font-title-btn">{{ phase.phase }}</h1>
                </div>
            </div>
        </div>
        <div v-else class="flex flex-col items-center justify-center">
            <img v-if="language == 'en'" src="@/assets/images/mapa_en.png" alt="test" class="rounded-md w-11/12 mb-4" />
            <img v-else src="@/assets/images/mapa.png" alt="test" class="rounded-md w-11/12 mb-4" />
        </div>
        <!--<div
            v-if="phasesCompleted != 9"
            @click="solveAllPhases"
            class="flex flex-row button bg-silver-dark/30 justify-center content-center text-center items-center w-full gap-4 mb-4"
        >
            <div class="font-title-btn w-12/12">DEMO</div>
            <inline-svg
                :src="require('@/assets/icons/dog-solid.svg')"
                alt="Mapa"
                class="w-12 h-16 mr-6 fill-silver-light"
            />
            <inline-svg
                :src="require('@/assets/icons/bible-solid.svg')"
                alt="Mapa"
                class="w-12 h-16 mr-6 fill-silver-light"
            />
        </div>-->
        <div
            v-if="phasesCompleted == 9 && $store.state.storeGameData.codeOpened"
            class="flex flex-row bg-silver-dark/30 button justify-center content-center text-center items-center w-full gap-4 mb-4"
        >
            <div class="ml-6 font-title-btn w-11/12">{{ $t("home.code_access_date") }}</div>
            <inline-svg
                :src="require('@/assets/icons/key-solid.svg')"
                alt="Code"
                class="w-12 h-16 mr-6 fill-silver-light"
            />
        </div>
        <div
            v-else-if="phasesCompleted == 9"
            @click="open('code')"
            class="flex flex-row bg-silver-dark/30 button justify-center content-center text-center items-center w-full gap-4 mb-4"
        >
            <div class="ml-6 font-title-btn w-11/12">{{ $t("home.code_access_unlocked") }}</div>
            <inline-svg
                :src="require('@/assets/icons/lock-open-solid.svg')"
                alt="Code"
                class="w-12 h-16 mr-6 fill-silver-light"
            />
        </div>
        <div
            v-else
            class="flex flex-row bg-silver-dark/30 button justify-center content-center text-center items-center w-full gap-4 mb-4"
        >
            <div class="ml-6 font-title-btn w-11/12">{{ $t("home.code_access") }}</div>
            <inline-svg
                :src="require('@/assets/icons/lock-solid.svg')"
                alt="Code"
                class="w-12 h-16 mr-6 fill-silver-light"
            />
        </div>
        <div
            v-if="phasesCompleted == 9 && $store.state.storeGameData.mapOpened"
            @click="open('map')"
            class="flex flex-row button bg-silver-dark/30 justify-center content-center text-center items-center w-full gap-4"
        >
            <div class="ml-6 font-title-btn w-11/12">{{ $t("home.map_showed") }}</div>
            <inline-svg
                :src="require('@/assets/icons/map-marker-alt-solid.svg')"
                alt="Mapa"
                class="w-12 h-16 mr-6 fill-silver-light"
            />
        </div>
        <div
            v-else-if="phasesCompleted == 9"
            @click="open('map')"
            class="flex flex-row button bg-silver-dark/30 justify-center content-center text-center items-center w-full gap-4"
        >
            <div class="ml-6 font-title-btn w-11/12">{{ $t("home.map_unlocked") }}</div>
            <inline-svg
                :src="require('@/assets/icons/lock-open-solid.svg')"
                alt="Mapa"
                class="w-12 h-16 mr-6 fill-silver-light"
            />
        </div>
        <div
            v-else
            class="flex flex-row button bg-silver-dark/30 justify-center content-center text-center items-center w-full gap-4"
        >
            <div class="ml-6 font-title-btn w-11/12">{{ $t("home.map_locked") }}</div>
            <inline-svg
                :src="require('@/assets/icons/lock-solid.svg')"
                alt="Mapa"
                class="w-12 h-16 mr-6 fill-silver-light"
            />
        </div>
        <CookiesBanner />
    </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import CookiesBanner from "@/components/CookiesBanner.vue";

export default {
    props: {},
    components: { CookiesBanner },
    beforeCreate() {},
    setup(props) {
        const router = useRouter();
        const store = useStore();
        const route = useRoute();
        const phasesCompleted = ref(0);
        const language = ref("ca");
        const phases = ref([
            { phase: 1, disabled: false, completed: store.state.storeGameData.phasesValidated[0] },
            {
                phase: 2,
                disabled: !store.state.storeGameData.phasesValidated[0],
                completed: store.state.storeGameData.phasesValidated[1],
            },
            {
                phase: 3,
                disabled: !store.state.storeGameData.phasesValidated[1],
                completed: store.state.storeGameData.phasesValidated[2],
            },
            {
                phase: 4,
                disabled: !store.state.storeGameData.phasesValidated[2],
                completed: store.state.storeGameData.phasesValidated[3],
            },
            {
                phase: 5,
                disabled: !store.state.storeGameData.phasesValidated[3],
                completed: store.state.storeGameData.phasesValidated[4],
            },
            {
                phase: 6,
                disabled: !store.state.storeGameData.phasesValidated[4],
                completed: store.state.storeGameData.phasesValidated[5],
            },
            {
                phase: 7,
                disabled: !store.state.storeGameData.phasesValidated[5],
                completed: store.state.storeGameData.phasesValidated[6],
            },
            {
                phase: 8,
                disabled: !store.state.storeGameData.phasesValidated[6],
                completed: store.state.storeGameData.phasesValidated[7],
            },
            {
                phase: 9,
                disabled: !store.state.storeGameData.phasesValidated[7],
                completed: store.state.storeGameData.phasesValidated[8],
            },
        ]);
        const phaseSelected = (phase) => {
            console.log("Phase " + phase + " selected");
            router.push({ name: "story", query: { phase: phase } });
        };
        onMounted(() => {
            phasesCompleted.value = 0;
            Object.keys(store.state.storeGameData.phasesValidated).forEach((key) => {
                if (store.state.storeGameData.phasesValidated[key]) {
                    phasesCompleted.value++;
                }
                console.log(phasesCompleted.value);
            });
            language.value = localStorage.language;
        });

        const solveAllPhases = () => {
            store.commit("setPhasesValidated", { phase_id: 1, validated: true });
            store.commit("setPhasesValidated", { phase_id: 2, validated: true });
            store.commit("setPhasesValidated", { phase_id: 3, validated: true });
            store.commit("setPhasesValidated", { phase_id: 4, validated: true });
            store.commit("setPhasesValidated", { phase_id: 5, validated: true });
            store.commit("setPhasesValidated", { phase_id: 6, validated: true });
            store.commit("setPhasesValidated", { phase_id: 7, validated: true });
            store.commit("setPhasesValidated", { phase_id: 8, validated: true });
            store.commit("setPhasesValidated", { phase_id: 9, validated: true });
            router.go();
        };

        const open = (item) => {
            if (item == "code") {
                store.commit("setOpenCode");
            } else if (item == "map") {
                store.commit("setOpenMap");
            }
        };

        return {
            phaseSelected,
            phases,
            phasesCompleted,
            solveAllPhases,
            open,
            language,
        };
    },
};
</script>

<style scoped>
.button-number {
    font-size: 22px;
    cursor: pointer;
    border-radius: 15px;
    width: 90px;
    height: 90px;
    box-shadow: 0 5px 5px #000;
    @apply flex flex-row justify-center items-center bg-silver-dark;
}

.button {
    font-size: 15px;
    cursor: pointer;
    border-radius: 15px;
    @apply flex flex-row justify-center items-center h-24 border-solid border-4 border-silver-dark;
}

.number {
    @apply text-silver-light;
}
.icon-completed {
    position: absolute;
    width: 25px;
    height: 25px;
    bottom: 16px;
    right: 5px;
    @apply fill-silver-light;
}
a:link {
    color: green;
    background-color: transparent;
    text-decoration: none;
}
a:visited {
    color: pink;
    background-color: transparent;
    text-decoration: none;
}
a:hover {
    color: red;
    background-color: transparent;
    text-decoration: underline;
}
a:active {
    color: yellow;
    background-color: transparent;
    text-decoration: underline;
}
</style>

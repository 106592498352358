<template>
    <div class="flex flex-col text-center justify-center items-center qr-container">
        <div class="qr-div"><span></span></div>
        <div class="qr-stream-div"></div>
        <div class="font-cta my-4 text-lg w-10/12 text-left">
            {{ $t("scan_qr.title") }}
            <hr class="h-1 bg-silver-light rounded-xl border-none" />
        </div>
        <qrcode-stream class="qr-stream" @decode="onDecode" @init="onInit" />

        <ActionBar :pageBack="redirect" :showValidate="false" />
    </div>
</template>

<script>
import ActionBar from "@/components/ActionBar.vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
export default {
    props: {},
    components: { ActionBar },

    setup(props) {
        const router = useRouter();
        const route = useRoute();
        const store = useStore();
        const phase = route.query.phase;
        const redirect = () => {
            router.push("/game?phase=" + phase);
        };
        const onDecode = (decodedString) => {
            if (decodedString != "") {
                /*if (phase == 5) {
                    store.commit("phase5SetResults", { fact: parseInt(decodedString) });
                    redirect();
                }*/
                if (phase == 8) {
                    const number = decodedString.substr(decodedString.length - 1);
                    store.commit("phase8SetQRScanned", { code: parseInt(number) });
                    redirect();
                }
            }
        };

        const onInit = async (promise) => {
            // show loading indicator

            try {
                const { capabilities } = await promise;

                console.log("successfully initialized");
            } catch (error) {
                if (error.name === "NotAllowedError") {
                    console.log("user denied camera access permisson");
                } else if (error.name === "NotFoundError") {
                    console.log("no suitable camera device installed");
                } else if (error.name === "NotSupportedError") {
                    console.log("page is not served over HTTPS (or localhost)");
                } else if (error.name === "NotReadableError") {
                    console.log("maybe camera is already in use");
                } else if (error.name === "OverconstrainedError") {
                    console.log("did you requested the front camera although there is none?");
                } else if (error.name === "StreamApiNotSupportedError") {
                    console.log("browser seems to be lacking features");
                }
            } finally {
                // hide loading indicator
            }
        };

        return { redirect, onDecode, onInit };
    },
};
</script>

<style lang="scss" scoped>
.qr-div {
    z-index: 2;
    animation: blinker 8s linear infinite;
}
.qr-stream-div {
    z-index: 1;
    top: 11vh;
    right: 0vh;
    left: 0vh;
    bottom: 0vh;

    position: fixed;
    border: solid rgba(66, 66, 66, 0.5);
    border-width: 12vh 6vh 19vh 6vh;
    -webkit-background-clip: padding-box; /* for Safari */
    background-clip: padding-box; /* for IE9+, Firefox 4+, Opera, Chrome */
}
.qr-container {
    height: calc(100vh - 78px);
}
.qr-div:before,
.qr-div:after,
.qr-div > :first-child:before,
.qr-div > :first-child:after {
    position: fixed;
    width: 80px;
    height: 80px;
    content: " ";
    @apply border-4 border-silver-light border-solid bg-transparent;
}
.qr-div:before {
    top: 22vh;
    left: 5vh;
    border-width: 5px 0 0 5px;
}
.qr-div:after {
    top: 22vh;
    right: 5vh;
    border-width: 5px 5px 0 0;
}
.qr-div > :first-child:before {
    bottom: 18vh;
    right: 5vh;
    border-width: 0 5px 5px 0;
}
.qr-div > :first-child:after {
    bottom: 18vh;
    left: 5vh;
    border-width: 0 0 5px 5px;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}
</style>

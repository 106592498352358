const es = {
    general: {
        subtitle: "RESUELVE LOS 9 RETOS Y ACCEDE A LA SALA SECRETA DE LA EXPOSICIÓN",
        play: "EMPEZAR",
        back: "ATRÁS",
        forward: "SIGUIENTE"
    },
    notifications: {
        phase_validated_title: "¡Enhorabuena!",
        phase_validated_description: "Has completado la fase con éxito. ¡Sigue así!",
        phase_failed_title: "Ya casi lo tienes...",
        phase_failed_description: "No has completado la fase. ¡Vuelve a intentarlo!",
        register_ok: "Te has registrado correctamente.",
        register_fail: "No te has podido registrar. Vuelve a intentarlo.",
        phase_already_validated_title: "¡Hey! Vuelve a atrás",
        phase_already_validated_description: "Ya habías completado esta fase.",
    },
    scan_qr: {
        title: "ESCANEA EL QR"
    },
    action_bar: {
        back: "ATRÁS",
        validate: "VALIDAR",
        next: "SIGUIENTE",
    },
    register: {
        description: "Regístrate para empezar a jugar.",
        name: "Nombre",
        surname: "Apellido",
        email: "Correo",
        zip_code: "Código postal",
        terms_conditions: "He leído y acepto los términos y condiciones.",
        receive_information: "Quiero recibir información comercial.",
        access: "ACCEDER",
    },
    home: {
        code_access: "CÓDIGO DE ACCESO A LA SALA SECRETA",
        map_locked: "MAPA DE LA SALA SECRETA",
        map_unlocked: "ACCEDE AL MAPA AQUÍ",
        code_access_unlocked: "DESBLOQUEA EL CÓDIGO AQUÍ",
        code_access_date: "¿EN QUÉ AÑO SE ESTRENÓ CRIMS EN LA RADIO? RESPUESTA: C+AÑO",
        map_showed: "USA EL MAPA PARA ENCONTRAR LA SALA SECRETA",
    },
    phase1: {
        title: "1. ANAGRAMA",
        story: "Una de las habilidades del investigador es prestar atención a cualquier detalle por pequeño que sea. Fíjate en esta primera sala, ¿ves algo que te llame la atención?",
        description: "Encuentra las letras marcadas y completa la frase siguiendo el siguiente código de colores:",
    },
    phase2: {
        title: "2. VENTANAS",
        story: "Para entender a un asesino tienes que pensar cómo él, meterte en su mente. Los crímenes pueden tener diferentes detonantes: motivos económicos, religiosos, morales, sexuales o simplemente por pura violencia gratuita. Para resolver esta fase, fíjate en las 7 ventanas que encontrarás en la segunda sala.",
        description: "Relaciona cada descripción con el nombre del caso correspondiente:",
        case_1: "YAYA ANITA",
        case_2: "ANGI",
        case_3: "ISIDRE",
        case_4: "MACHALA",
        case_5: "SONIA",
        case_6: "LA FLOR",
        case_7: "CA N'AMAT",
        case_1_description: "Es la panadera del barrio y accede al domicilio de las víctimas aprovechando la relación de amistad que mantiene con ellas. Una vez dentro, las distrae pidiendo un vaso de agua para atacarlas por sorpresa. Una vez muertas, les roba dinero y objetos de valor.",
        case_2_description: "Para mantener su estilo de vida, suplanta la identidad de una compañera de trabajo para contratar seguros de vida y préstamos por valor de miles de euros. Dos años después, la mata para cobrar los seguros contratados.",
        case_3_description: "Cansada de su matrimonio y ahogada por las deudas que había generado su adicción al bingo, encarga a dos sicarios el asesinato de su marido para cobrar los seguros de vida. Los delincuentes resultan ser un poco torpes y necesitan más de un intento para poner fin a su vida.",
        case_4_description: "Aprovechando los diferentes tipos de trabajos que tiene, vigila y acosa a sus víctimas. Una vez indefensas, las estrangula y agrede sexualmente. ",
        case_5_description: "Seis jóvenes de Barcelona salen de “cacería”, buscando donde depositar toda su rabia, odio y agresividad. Encuentran su objetivo durmiendo en el Parque de la Ciutadella: dos mujeres transexuales sin hogar.",
        case_6_description: "En Colombia, un marido y padre de familia. En Mongat, un inmigrante homosexual tranquilo y trabajador. En la intimidad, un exmilitar violento de ideología neonazi. Su víctima mortal representa todo aquello que odia de sí mismo: una persona inmigrante, de color y homosexual.",
        case_7_description: "La relación entre hermanos y sus parejas, que conviven bajo el mismo techo, se tensa hasta que una de las parejas decide poner fin a la vida de la otra. Inspirados en una famosa serie de televisión, descuartizan los cadáveres y los esconden en el congelador de la casa que comparten.",
        case: "¿A qué caso corresponde esta descripción?",
        t_description: "DESCRIPCIÓN",
        window_title: "VENTANA"
    },
    phase3: {
        title: "3. RETRATOS ROBOT",
        selected: "SELECCIONADOS",
        story: "La mente nos puede jugar malas pasadas: es muy posible que nuestros recuerdos cambien, que las caras se difuminen y que, incluso, recordemos cosas que nunca llegaron a pasar de aquella manera. Fíjate en las personas que salen en la rueda de reconocimiento. ¿Sabrías reconocer sus retratos robot?",
        description: "Selecciona los retratos robot de 2 de los sospechosos que has visto en la rueda de reconocimiento de la sala 3:",
    },
    phase4: {
        title: "4. INTERROGATORIO",
        story: "El peso del silencio se hace muy evidente en los interrogatorios. Las personas implicadas en los casos pueden esconder información durante años y hacer vida normal como si no hubiera pasado nada. En esta prueba os presentamos un caso FICTICIO, para que os podáis poner en el papel del entrevistador.",
        description: "Revisa las entrevistas de 3 personas relacionadas con nuestro caso FICTICIO. <br>Con esta información, determina qué afirmaciones son reales.",
        interrogations: "SELECCIONA UNA PERSONA PARA INTERROGAR:",
        what_know: "¿QUÉ SABEMOS?",
        person_empty: "Interroga a la persona para ver su declaración.",
        reconstruction: "RECONSTRUCCIÓN DE LOS HECHOS",
        description_contradiction: "¿Cuáles de las siguientes afirmaciones son verdad?",
        plausible: "vERDADERO",
        contradiction: "FALSO",
        person_1: "Recepcionista",
        person_2: "Pareja",
        person_3: "Compañera Trabajo",
        person_3_br: "Compañera<br>Trabajo",
        statement_1_A: "- La víctima y su compañera de trabajo van a ver una exposición la tarde/noche de los hechos.",
        statement_1_B: "- La víctima iba bien vestida la noche de los hechos.",
        statement_1_C: "- Encuentra el cadáver la mañana siguiente en un contenedor cercano al Palau.",
        statement_2_A: "- Era la pareja de la víctima.",
        statement_2_B: "- La última vez que vio a la víctima fue la tarde del día de los hechos.",
        statement_2_C: "- La víctima tenía problemas con una compañera de trabajo.",
        statement_3_A: "- Tenía una aventura con la víctima.",
        statement_3_B: "- Sabía que la víctima tenía problemas con su pareja.",
        statement_3_C: "- Se va sola del Palau Robert, no con la víctima.",
        fact_1: "El recepcionista encuentra el cadáver la misma noche de los hechos.",
        fact_2: "La pareja dice que la víctima tenía problemas con una compañera de trabajo.",
        fact_3: "La compañera de trabajo sabía que la víctima tenía pareja.",
        fact_4: "Según la pareja, la víctima durmió en casa aquella noche.",
        fact_5: "Según el recepcionista, la víctima iba arreglada la noche de los hechos.",
        fact_6: "La pareja dice que la víctima dejó el trabajo por problemas de salud.",
        question_A_1: " ¿A qué hora encontraste el cadáver?",
        question_A_2: "¿Conocías a la víctima?",
        question_A_3: "¿Cómo iba vestida la víctima?",
        question_B_1: "¿Qué relación tenías con la víctima?",
        question_B_2: "¿Sabes si la víctima tenía algún enemigo conocido?",
        question_B_3: "¿Cuándo te diste cuenta de su desaparición?",
        question_C_1: "¿Qué relación tenías con la víctima?",
        question_C_2: "¿Cuándo fue la última vez que viste a la víctima?",
        question_C_3: "¿Sabes si tenía algún enemigo conocido?",
        response_A_1: "Por la mañana cuando volvía al Palau sobre las 8.30 vi un pie que salía de una bolsa en unos contenedores próximos.",
        response_A_2: "La noche anterior vino a ver la exposición con una chica. Me pareció que eran pareja, discutieron mucho pero se fueron juntos.",
        response_A_3: "Iban arreglados los dos... Él llevaba pantalones vaqueros y una camisa blanca.",
        response_B_1: "Éramos pareja desde hacía 8 años.",
        response_B_2: "Hacía poco que había dejado el trabajo porque había tenido problemas con una compañera... Ella estaba obsesionada con él, no lo dejaba en paz.",
        response_B_3: "Aquella noche no durmió en casa, no era normal en él. Me preocupé pero como aquella tarde habíamos discutido pensé que quizás estaba con los amigos. La mañana siguiente me despertó la llamada de la policía para decirme que habían encontrado su cuerpo sin vida.",
        response_C_1: "Éramos compañeros de trabajo y estábamos enamorados... él tenía pareja desde hacía años pero no estaba enamorado de ella, me había prometió que la dejaría al llegar a casa.",
        response_C_2: "Aquella tarde estuvimos juntos en el Palau Robert. Después de ver la exposición él me dijo que volvía a su casa y que al día siguiente todo sería diferente. La quería dejar aquella misma noche. Después de ver la exposición, volví a casa.",
        response_C_3: "Que yo sepa nadie… se llevaba bien con todo el mundo. La única que le causaba problemas era su pareja... De hecho lo obligó a dejar el trabajo porque estaba celosa de mí.",
        interrogation_suspect: "INTERROGATORIO SOSPECHOSO",
        interrogator: "INTERROGADOR",
        suspect: "SOSPECHOSO",
        question: "PREGUNTA",
        response: "RESPUESTA",
        questions: "PREGUNTAS"

    },
    phase5: {
        title: "5. CAPÍTULOS",
        story: "La llegada de CRIMS a la televisión fué un gran éxito. El capítulo de la Noia de Portbou rompió todos los récords de audiencia del programa. En esta prueba encontrarás el nombre de 6 capítulos, ¿recuerdas en qué orden se emitieron?",
        description: "Ordena los episodios según su fecha de emisión en la TV. Encuentra la información online o en la exposición:",
        facts_found: "DATES TROBADES",
        scan_next: "ESCANEJA <br>EL SEGÜENT",
        qr_1_title: "Brito y Picatoste",
        qr_2_title: "Machala",
        qr_3_title: "Ca n'Amat",
        qr_4_title: "Guardia Urbana",
        qr_5_title: "Yaya Anita",
        qr_6_title: "La chica de Portbou",
        qr_1_description: "Evi Rauter deja una nota en el piso de su hermana en Florencia que dice: “Me voy a Siena.”",
        qr_2_description: "Una vecina de Portbou ve que hay una chica colgada de un pino cerca del cementerio. No lleva ningún tipo de identificación y el caso se enfría.",
        qr_3_description: "El equipo de CRIMS se interesa por el caso y graba el primer episodio de “La chica de Portbou”.",
        qr_4_description: "Un canal de TV Austríaco, gracias a la colaboración del equipo de CRIMS, emite un episodio sobre “La chica de Portbou”. Una mujer italiana de vacaciones en Austria ve el capítulo e identifica la chica.",
        qr_5_description: "El equipo de CRIMS recibe un mensaje de la TV Austríaca diciendo que han identificado a la chica: “WE FOUND THE GIRL!” (“¡Hemos encontrado a la chica!)",
        qr_6_description: "Se emiten los capítulos de CRIMS sobre “La chica de Portbou” en TV3, donde podemos ver que la familia confirma la identidad de Evi Rauter, una joven italiana que llevaba más de 32 años desaparecida.",
    },
    phase6: {
        title: "6. VIDEOVIGILANCIA",
        story: "Gracias a los avances tecnológicos se han podido resolver muchos casos, incluso años después de que ocurrieran. En este caso, tendrás que estar atento a las cámaras de videovigilancia para identificar una serie de objetos.",
        description: "¿Has visto los siguientes objetos en las cámaras de videovigilancia? Fíjate en los monitores para comprobarlo:",
        cameras: "CÁMARAS",
        name_1: "LIBRETA ROJA",
        name_2: "GATO YAYA ANITA",
        name_3: "DVDS DEXTER",
        name_4: "PELUCA MORENA",
        name_5: "HACHA",
        name_6: "BANDERA",
        name_7: "FLORES",
        name_8: "BOLSA DEPORTE",
        yes: "SÍ",
        no: "NO",


    },
    phase7: {
        title: "7. ESCUCHA",
        story: "Las escuchas policiales son una herramienta primordial. Los investigadores pasan horas escuchando conversaciones para encontrar algún detalle que los pueda llevar en la dirección correcta. Para resolver esta prueba, tendréis que encontrar unos teléfonos y escuchar los mensajes.",
        description: "Encuentra los teléfonos que cuelgan del techo y responde las siguientes preguntas según lo que escuches:",
        question_1: "En una llamada intervenida del caso Brito y Picatoste, dos hombres hablan de subir por una carretera, de qué carretera se trata?",
        question_2: "En una conversación entre Isaac y su amiga, él dice “¿Siempre que té hablo de mi ____, te tienes que reír o qué?”. ¿De que habla?",
        question_3: "¿Cuánto dinero ofreció la mujer de Isidre por matar a su marido?",
        /*question_4: "En una conversación entre Isaac y su amiga, él dice “¿Siempre que té hablo de mí ____, te tienes que reír o qué?”. ¿De que habla?",
        question_5: "En una llamada entre los asesinos de Isidre, uno de ellos dice que tiene una parte del cuerpo muy hinchada. ¿De qué parte del cuerpo habla?",*/
        response_1_A: "Tibidado",
        response_1_B: "Collserola",
        response_1_C: "Sant Cugat",
        response_1_D: "Carretera de las Aiguas",
        response_2_A: "novia",
        response_2_B: "madre",
        response_2_C: "casa",
        response_2_D: "pasión",
        response_3_A: "5 millones de pesetas",
        response_3_B: "7 millones de pesetas",
        response_3_C: "1 millión de pesetas",
        response_3_D: "700 mil pesetas",
        /*response_4_A: "casa",
        response_4_B: "madre",
        response_4_C: "novia",
        response_4_D: "pasión",
        response_5_A: "Labio",
        response_5_B: "Rodilla",
        response_5_C: "Mano",
        response_5_D: "Ojos",*/
    },
    phase8: {
        title: "8. DESCIFRAR",
        story: "Brito y Picatoste escaparon de una prisión el Lleida dejando un reguero de sangre por toda Cataluña. Se comunicaban con diferentes códigos que, en teoría, tenían que memorizar y quemar. La policía encontró la leyenda de su código secreto y pudieron descifrar sus mensajes.",
        description: "Encuentra los 4 códigos QR repartidos por la sala para conseguir la clave de los códigos de los prófugos. <br>Cuando los encuentres, úsalos para descifrar el siguiente mensaje:",
        scan_qr: "ESCANEA LOS QR PARA DESCIFRAR EL CÓDIGO"
    },
    phase9: {
        title: "9. ESTADÍSTICAS",
        story: "Aunque en CRIMS os mostremos la parte más oscura de nuestra sociedad, las cifras de delincuencia en Cataluña son muy bajas. Encuentra la pantalla con el mapa delincuencial de Cataluña para resolver el siguiente enigma.",
        description: "Consulta la pantalla táctil o accede al siguiente enlace para responder las siguientes preguntas:",
        question_1: "¿Cuántas tentativas de asesinato hubo en Cataluña en 2019?",
        question_2: "Dos de las tentativas de la pregunta anterior sucedieron en un mismo mes. ¿De qué mes se trata?",
        question_3: "¿Cuántos casos de tentativa de asesinato hubo en Cataluña el 2020?",
        question_4: "¿Cuántos asesinatos consumados hubo en Cataluña en 2020? ",
        access_web: "ACCEDE A LA WEB",
    },
    cookies: {
        title: "Cookies",
        text_accept: "Esta web usa cookies técnicas. Si quieres saber más, haz ",
        click_here: "click aquí.",
        btn_accept: "OK",
        text: "Nuestra Política de Cookies tiene por finalidad informarle de forma clara y completa sobre el funcionamiento y finalidad de las cookies que se utilizan en nuestro Sitio Web. " +
            "En caso de que quiera recabar más información sobre las cookies, puede remitir un correo electrónico en tal sentido a zoopa{'@'}zoopa.es.<br><br>¿Qué es una 'cookie'?<br><br> Una cookie es un archivo que se descarga en su equipo terminal/dispositivo (ordenador o teléfono móvil) con la finalidad de almacenar datos que podrán ser actualizados y recuperados por la entidad responsable de su instalación. Nuestro Sitio web utiliza “cookies” con la finalidad de poder recabar información acerca de su funcionamiento y uso por los usuarios del mismo. Finalidad<br><br>Las cookies pueden recabar información sobre sus visitas a nuestro Sitio Web, la frecuencia y duración de las mismas, las páginas visionadas y el tiempo que ha estado en nuestro Sitio Web. " +
            "Le informamos de que podemos utilizar cookies con la finalidad de: -Proporcionarle una mejor experiencia en el uso del mismo.<br><br>Cookies utilizadas en la web<br><br>Nuestro Sitio Web utiliza cookies propias con diferentes finalidades, concretamente:<br><br> " +
            "Cookies propias<br><br>Son enviadas a su ordenador y gestionadas exclusivamente por nosotros para el mejor funcionamiento del Sitio Web. Estas cookies permanecen su navegador, permitiéndonos identificarle como usuario recurrente del Sitio Web y personalizar su contenido para ofrecerle contenidos ajustados a sus preferencias.<br></br>Cookies utilizadas en la web<br><br>Cookies técnicas<br><br>Las cookies técnicas son aquellas imprescindibles para el funcionamiento de la página web, como por ejemplo gestionar la autenticación o mantenimiento de una sesión de un usuario registrado en la página web. La desactivación de estas cookies puede impedir el correcto funcionamiento de la página.<br><br>Consentimiento<br><br>Al navegar y continuar en nuestro Sitio Web no estará consintiendo el uso de las cookies antes enunciadas, en las condiciones contenidas en la presente Política de Cookies. Es preciso aceptarlas expresamente o rechazarlas. Le informamos de que, dado que las cookies no son necesarias para navegar en nuestro Sitio Web, puede bloquearlas o deshabilitarlas a través de la configuración de su navegador. Si las bloquea o deshabilita podrá seguir usando nuestro Sitio Web, aunque el uso de algunos de sus servicios podrá ser limitado y por tanto su experiencia en nuestro Sitio Web menos satisfactoria. Si desea revocar su consentimiento informado respecto a nuestra política de cookies, deberá eliminarlas en su dispositivo a través de la configuración de sus navegadores de Internet. Estos son los enlaces de los distintos navegadores que informan sobre las cookies: Firefox Chrome Explorer Safari Opera Le recomendamos revisar esta política cada vez que acceda a nuestro Sitio Web con el objetivo de estar adecuadamente informado sobre posibles cambios en la misma.<br><br>",
    }
}

module.exports = {
    es
}

<template>
    <div>
        <router-view />
        <DumbScript />
        <NotificationGroup group="success">
            <div
                class="fixed inset-0 flex items-start justify-end p-6 px-4 py-6 pointer-events-none"
                style="z-index: 999999"
            >
                <div class="w-full max-w-sm">
                    <Notification
                        v-slot="{ notifications, close }"
                        enter="transform ease-out duration-300 transition"
                        enter-from="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-4"
                        enter-to="translate-y-0 opacity-100 sm:translate-x-0"
                        leave="transition ease-in duration-500"
                        leave-from="opacity-100"
                        leave-to="opacity-0"
                        move="transition duration-500"
                        move-delay="delay-300"
                    >
                        <div
                            class="flex w-full max-w-sm mx-auto mt-4 overflow-hidden bg-silver-dark rounded-lg shadow-md border-2 border-silver-light border-solid"
                            v-for="notification in notifications"
                            :key="notification.id"
                        >
                            <div class="flex items-center justify-center w-12 bg-green-400">
                                <svg
                                    class="w-10 h-10 text-success-100 my-auto fill-silver-light"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    aria-hidden="true"
                                >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                    />
                                </svg>
                            </div>

                            <div class="px-4 py-2 -mx-3">
                                <div class="mx-3">
                                    <p class="font-semibold text-text-120 font-title-btn">{{ notification.title }}</p>
                                    <p class="text-sm font-semibold text-text-100 font-phase-description">
                                        {{ notification.text }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Notification>
                </div>
            </div>
        </NotificationGroup>
        <NotificationGroup group="error">
            <div
                class="fixed inset-0 flex items-start justify-end p-6 px-4 py-6 pointer-events-none"
                style="z-index: 999999"
            >
                <div class="w-full max-w-sm">
                    <Notification
                        v-slot="{ notifications }"
                        enter="transform ease-out duration-300 transition"
                        enter-from="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-4"
                        enter-to="translate-y-0 opacity-100 sm:translate-x-0"
                        leave="transition ease-in duration-500"
                        leave-from="opacity-100"
                        leave-to="opacity-0"
                        move="transition duration-500"
                        move-delay="delay-300"
                    >
                        <div
                            class="flex w-full max-w-sm mx-auto mt-4 overflow-hidden bg-silver-dark rounded-lg shadow-md border-2 border-silver-light border-solid"
                            v-for="notification in notifications"
                            :key="notification.id"
                        >
                            <div class="flex items-center justify-center w-12 bg-red-400">
                                <svg
                                    class="w-6 h-6 text-danger-100 fill-silver-light my-auto"
                                    viewBox="0 0 40 40"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M20 3.36667C10.8167 3.36667 3.3667 10.8167 3.3667 20C3.3667 29.1833 10.8167 36.6333 20 36.6333C29.1834 36.6333 36.6334 29.1833 36.6334 20C36.6334 10.8167 29.1834 3.36667 20 3.36667ZM19.1334 33.3333V22.9H13.3334L21.6667 6.66667V17.1H27.25L19.1334 33.3333Z"
                                    ></path>
                                </svg>
                            </div>

                            <div class="px-4 py-2 -mx-3">
                                <div class="mx-3">
                                    <p class="font-semibold text-text-120 font-title-btn">{{ notification.title }}</p>
                                    <p class="text-sm font-semibold text-text-100 font-phase-description">
                                        {{ notification.text }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Notification>
                </div>
            </div>
        </NotificationGroup>
    </div>
</template>
<script>
import DumbScript from "./components/DumbScript.vue";
export default {
    components: {
        DumbScript,
    },
};
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    max-width: 600px;
}
body {
    background-image: url("@/assets/images/background.jpg");
    @apply flex justify-center items-center content-center;
}
</style>

<template>
    <span>
        <div class="mb-16"></div>
        <div class="flex flex-row action-bar bg-blue-dark h-16">
            <div class="flex flex-row justify-center items-center p-3 float-left cursor-pointer">
                <inline-svg
                    :src="require('@/assets/icons/caret-left-solid.svg')"
                    alt="Tornar enrere"
                    class="w-8 h-8 fill-silver-light"
                />
                <div class="font-title-btn" @click="pageBack ? pageBack() : $router.push('/')">
                    {{ $t("action_bar.back") }}
                </div>
            </div>
            <div
                v-if="showValidate"
                class="flex flex-row button my-3 justify-center items-center p-2 absolute right-0 mx-2"
            >
                <div class="font-title-btn" @click="validatePhase">{{ $t("action_bar.validate") }}</div>
                <inline-svg
                    :src="require('@/assets/icons/validate-solid.svg')"
                    alt="Validar prova"
                    class="ml-2 w-6 h-6 fill-silver-light"
                />
            </div>
            <div
                v-if="showNext"
                class="flex flex-row button my-3 justify-center items-center p-2 absolute right-0 mx-2"
            >
                <div class="font-title-btn" @click="pageBack ? pageBack() : $router.push('/')">
                    {{ $t("action_bar.next") }}
                </div>
                <inline-svg
                    :src="require('@/assets/icons/validate-solid.svg')"
                    alt="Validar prova"
                    class="ml-2 w-6 h-6 fill-silver-light"
                />
            </div>
        </div>
    </span>
</template>

<script>
import ActionBar from "@/components/ActionBar.vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { axiosToken } from "../libs/http";
import { notify } from "notiwind";
import { useI18n } from "vue-i18n";

export default {
    components: { ActionBar },
    props: {
        pageBack: {
            type: [Function, Boolean],
            default: false,
        },
        showValidate: {
            type: Boolean,
            default: true,
        },
        showNext: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const route = useRoute();
        const router = useRouter();
        const store = useStore();
        const { t } = useI18n();
        const validatePhase = () => {
            console.log(route.query.phase);
            //if (!store.state.storeGameData.phasesValidated[route.query.phase]) {
            axiosToken({
                method: "put",
                url: "validate_phase",
                body: {
                    name: "Phase " + route.query.phase,
                    phase_id: route.query.phase,
                    data: store.state.storePhasesData["phase" + route.query.phase + "Data"].answers
                        ? store.state.storePhasesData["phase" + route.query.phase + "Data"].answers
                        : store.state.storePhasesData["phase" + route.query.phase + "Data"].factsScanned
                        ? store.state.storePhasesData["phase" + route.query.phase + "Data"].factsScanned
                        : store.state.storePhasesData["phase" + route.query.phase + "Data"].statements
                        ? store.state.storePhasesData["phase" + route.query.phase + "Data"].statements
                        : store.state.storePhasesData["phase" + route.query.phase + "Data"].cameras
                        ? store.state.storePhasesData["phase" + route.query.phase + "Data"].cameras
                        : store.state.storePhasesData["phase" + route.query.phase + "Data"],
                    uuid: localStorage.uuid,
                },
            }).then((response) => {
                console.log(response);
                console.log(response.data.validated);
                if (response.data.validated) {
                    notify(
                        {
                            group: "success",
                            title: t("notifications.phase_validated_title"),
                            text: t("notifications.phase_validated_description"),
                        },
                        4000
                    );
                    setTimeout(function () {
                        router.push("/");
                    }, 1000);
                } else {
                    notify(
                        {
                            group: "error",
                            title: t("notifications.phase_failed_title"),
                            text: t("notifications.phase_failed_description"),
                        },
                        4000
                    );
                }
                store.commit("setPhasesValidated", {
                    phase_id: route.query.phase,
                    validated: response.data.validated,
                });
            });
            /*} else {
                notify(
                    {
                        group: "error",
                        title: t("notifications.phase_already_validated_title"),
                        text: t("notifications.phase_already_validated_description"),
                    },
                    4000
                );
            }*/
        };
        return { validatePhase };
    },
};
</script>

<style scoped>
.action-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    font-size: 15px;
    z-index: 1000;
}
.button {
    cursor: pointer;
    border-radius: 3px;

    @apply bg-silver-dark;
}
</style>

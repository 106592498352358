const url = window.location.pathname


const phase5Data = {
    factsScanned: []
}

const phase5Mutations = {
    phase5SetResults(state, data) {
        state.phase5Data.factsScanned = data.facts;
    }
}

const phase5DataResults = ({ commit, state, rootState }, data) => {
    commit("phase5SetResults", data.val())
}

export { phase5Data, phase5DataResults, phase5Mutations }
<template>
    <div>
        <div class="font-cta mb-4">{{ title }}</div>
        <div class="font-phase-description text-md text-left" v-html="description"></div>
        <hr class="mb-4 h-1 bg-silver-light rounded-xl border-none" />
    </div>
</template>

<script>
export default {
    props: {
        title: { type: String, default: "" },
        description: { type: String, default: "" },
    },
    components: {},

    setup(props) {
        return {};
    },
};
</script>

<style lang="scss" scoped>
.btn-answer {
    font-size: 10px;
    @apply bg-silver-light text-silver-dark rounded-md;
}
.btn-answer-outlined {
    font-size: 10px;

    @apply bg-transparent border-2 border-solid border-silver-light rounded-md;
}
</style>

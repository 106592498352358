<template>
    <div class="flex flex-row text-center justify-center items-center">
        <div class="mx-4 flex flex-col text-left text-xl items-center justify-center">
            <div class="font-cta my-4 text-2xl" v-html="$t('phase' + phase + '.title')" />
            <div class="font-phase-description text-md text-justify" v-html="$t('phase' + phase + '.story')" />
            <div class="flex flex-col items-center w-full mt-8">
                <input
                    class="flex flex-row text-center justify-center w-8/12 font-title-btn text-xl button px-2 py-4 h-10"
                    :value="$t('general.play')"
                    @click="startGame"
                />
            </div>
            <ActionBar :showValidate="false" />
        </div>
    </div>
</template>

<script>
import { ref, onUpdated } from "vue";
import { useRouter } from "vue-router";
import PhaseHeader from "@/components/PhaseHeader.vue";
import ActionBar from "@/components/ActionBar.vue";
import { useStore } from "vuex";
export default {
    props: {
        phase: { type: [Number, String], default: 0 },
    },
    components: { PhaseHeader, ActionBar },

    setup(props) {
        const router = useRouter();
        const store = useStore();

        const startGame = () => {
            router.push({ name: "game", query: { phase: props.phase } });
        };

        onUpdated(() => {
            checkPhaseBlocked();
        });

        const checkPhaseBlocked = () => {
            if (
                (!store.state.storeGameData.phasesValidated[props.phase - 2] ||
                    typeof store.state.storeGameData.phasesValidated[props.phase - 2] === "undefined") &&
                props.phase != 1
            ) {
                router.push("/");
            }
        };

        return {
            startGame,
        };
    },
};
</script>

<style scoped>
input {
    @apply rounded-md bg-silver-light h-8 px-2;
}
.button {
    border-radius: 50px;
    border: none;
    box-shadow: 0 5px 5px #000;
    @apply bg-silver-dark cursor-pointer;
}
</style>

<template>
    <div class="flex flex-col rounded-md border-solid border-3 border-silver-dark mb-2">
        <div class="flex flex-col items-center mx-4 text-center mb-2">
            <div class="flex flex-row gap-4 items-center mt-2 text-xl text-left w-full">
                <!--<div class="w-5/12 flex flex-col border-solid border-4 bg-silver-dark/30 border-silver-dark">
                    <img src="@/assets/images/test_boots.jpeg" :alt="item.name" class="w-full h-full" />
                </div>-->
                <div class="flex flex-col w-full items-center justify-center">
                    <div class="flex flex-row font-phase-description">{{ item.name }}</div>
                    <!--<div class="flex flex-row font-content">{{ $t("phase6.cameras") }}:</div>-->
                    <div class="flex flex-col font-title-btn justify-between gap-2 w-6/12">
                        <div
                            @click="numberSelected(item.id, 1)"
                            class="camera-number w-full"
                            :class="item.selected == 1 ? 'btn-number' : 'btn-number-outlined'"
                        >
                            {{ $t("phase6.yes") }}
                        </div>
                        <div
                            @click="numberSelected(item.id, 2)"
                            class="camera-number w-full"
                            :class="item.selected == 2 ? 'btn-number' : 'btn-number-outlined'"
                        >
                            {{ $t("phase6.no") }}
                        </div>
                        <!--<div
                            @click="numberSelected(item.id, 1)"
                            class="camera-number"
                            :class="item.selected == 1 ? 'btn-number' : 'btn-number-outlined'"
                        >
                            1
                        </div>
                        <div
                            @click="numberSelected(item.id, 2)"
                            class="camera-number"
                            :class="item.selected == 2 ? 'btn-number' : 'btn-number-outlined'"
                        >
                            2
                        </div>
                        <div
                            @click="numberSelected(item.id, 3)"
                            class="camera-number"
                            :class="item.selected == 3 ? 'btn-number' : 'btn-number-outlined'"
                        >
                            3
                        </div>
                        <div
                            @click="numberSelected(item.id, 4)"
                            class="camera-number"
                            :class="item.selected == 4 ? 'btn-number' : 'btn-number-outlined'"
                        >
                            4
                        </div>
                        <div
                            @click="numberSelected(item.id, 5)"
                            class="camera-number"
                            :class="item.selected == 5 ? 'btn-number' : 'btn-number-outlined'"
                        >
                            5
                        </div>
                        <div
                            @click="numberSelected(item.id, 6)"
                            class="camera-number"
                            :class="item.selected == 6 ? 'btn-number' : 'btn-number-outlined'"
                        >
                            6
                        </div>-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        item: { type: Object, default: {} },
        numberSelected: { type: Function, default: () => {} },
    },
    components: {},

    setup(props) {
        return {};
    },
};
</script>

<style lang="scss" scoped>
select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-image: url("@/assets/icons/sort-down-solid.svg");
    background-repeat: no-repeat, repeat;
    background-position: right 0.7em top 0, 0 0;
    background-size: 0.65em auto, 100%;
    @apply bg-silver-light;
}
.button {
    cursor: pointer;

    @apply bg-silver-dark rounded-md;
}

.camera-number {
    @apply border-solid border-2 border-silver-light flex flex-col text-center rounded-md;
}
.btn-number {
    @apply bg-silver-light text-silver-dark rounded-md cursor-pointer;
}
.btn-number-outlined {
    @apply bg-transparent rounded-md cursor-pointer;
}
</style>

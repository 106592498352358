<template>
    <div class="text-center justify-center items-center pb-2">
        <div class="text-left text-xl items-start justify-start">
            <PhaseHeader :title="$t('phase6.title')" :description="$t('phase6.description')" />
            <CameraCard v-for="item in items" :key="item" :item="item" :numberSelected="numberSelected" />
        </div>
    </div>
</template>

<script>
import CameraCard from "@/components/cards/CameraCard.vue";
import PhaseHeader from "@/components/PhaseHeader.vue";
import { useStore } from "vuex";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import shuffleArray from "@/libs/utils.js";
export default {
    props: {},
    components: { CameraCard, PhaseHeader },

    setup(props) {
        const store = useStore();
        const { t } = useI18n();
        const items = ref([
            {
                id: 1,
                //url: "@/assets/images/test_boots.jpeg",
                name: t("phase6.name_1"),
                selected: store.state.storePhasesData.phase6Data.cameras[1]
                    ? store.state.storePhasesData.phase6Data.cameras[1]
                    : 0,
            },
            {
                id: 2,
                //url: "@/assets/images/test_boots.jpeg",
                name: t("phase6.name_2"),
                selected: store.state.storePhasesData.phase6Data.cameras[2]
                    ? store.state.storePhasesData.phase6Data.cameras[2]
                    : 0,
            },
            {
                id: 3,
                //url: "@/assets/images/test_boots.jpeg",
                name: t("phase6.name_3"),
                selected: store.state.storePhasesData.phase6Data.cameras[3]
                    ? store.state.storePhasesData.phase6Data.cameras[3]
                    : 0,
            },
            {
                id: 4,
                //url: "@/assets/images/test_boots.jpeg",
                name: t("phase6.name_4"),
                selected: store.state.storePhasesData.phase6Data.cameras[4]
                    ? store.state.storePhasesData.phase6Data.cameras[4]
                    : 0,
            },
            {
                id: 5,
                //url: "@/assets/images/test_boots.jpeg",
                name: t("phase6.name_5"),
                selected: store.state.storePhasesData.phase6Data.cameras[5]
                    ? store.state.storePhasesData.phase6Data.cameras[5]
                    : 0,
            },
            {
                id: 6,
                //url: "@/assets/images/test_boots.jpeg",
                name: t("phase6.name_6"),
                selected: store.state.storePhasesData.phase6Data.cameras[6]
                    ? store.state.storePhasesData.phase6Data.cameras[6]
                    : 0,
            },
            {
                id: 7,
                //url: "@/assets/images/test_boots.jpeg",
                name: t("phase6.name_7"),
                selected: store.state.storePhasesData.phase6Data.cameras[7]
                    ? store.state.storePhasesData.phase6Data.cameras[7]
                    : 0,
            },
            {
                id: 8,
                //url: "@/assets/images/test_boots.jpeg",
                name: t("phase6.name_8"),
                selected: store.state.storePhasesData.phase6Data.cameras[8]
                    ? store.state.storePhasesData.phase6Data.cameras[8]
                    : 0,
            },
        ]);

        shuffleArray(items.value);

        const numberSelected = (id, number) => {
            const item_index = items.value.findIndex((obj) => obj.id == id);
            items.value[item_index].selected = number;
            store.commit("phase6SetResults", { item_id: id, camera_selected: number });
        };

        return { items, numberSelected };
    },
};
</script>

<style lang="scss" scoped></style>

const url = window.location.pathname


const phase6Data = {
    cameras: {}
}

const phase6Mutations = {
    phase6SetResults(state, data) {
        state.phase6Data.cameras[data.item_id] = data.camera_selected;
    }
}

const phase6DataResults = ({ commit, state, rootState }, data) => {
    commit("phase6SetResults", data.val())
}

export { phase6Data, phase6DataResults, phase6Mutations }
<template>
    <div class="scan-qr">
        <ScanQR />
    </div>
</template>

<script>
// @ is an alias to /src

import ScanQR from "@/components/ScanQR.vue";
import { useRouter } from "vue-router";
import { onMounted } from "vue";

export default {
    name: "ScanQRView",
    components: {
        ScanQR,
    },
    setup(props) {
        const router = useRouter();

        onMounted(() => {
            if (!localStorage.uuid) {
                router.push("/language");
            }
        });

        return {};
    },
};
</script>

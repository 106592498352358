<template>
    <div class="language">
        <Language />
    </div>
</template>

<script>
// @ is an alias to /src

import Language from "@/components/Language.vue";
import { useRouter } from "vue-router";
import { onBeforeMount, ref } from "vue";

export default {
    name: "LanguageView",
    components: {
        Language,
    },
    setup(props) {
        const router = useRouter();
        const register_success = ref(false);
        onBeforeMount(() => {
            if (!localStorage.register_success) {
                window.location.replace("https://www.ccma.cat/crims-exposicio-joc");
            }
            register_success.value = localStorage.register_success;
        });

        return { register_success };
    },
};
</script>

<template>
    <div class="flex flex-col rounded-md pb-2">
        <div class="flex flex-col items-center mx-4 text-center">
            <div class="flex flex-row gap-4 items-center mt-2 text-xl">
                <div class="font-cta text-sm">{{ $t("phase4.interrogation_suspect") }}</div>
            </div>
            <hr class="my-4 h-1 w-full bg-silver-light rounded-xl border-none" />
            <div class="flex flex-col text-left w-full text-lg">
                <div class="font-title-btn text-left text-xl">{{ $t("phase4.interrogator") }}:</div>
                <p class="font-phase-description">
                    {{ questionSelected == 0 ? "" : questions.filter((f) => f.id == questionSelected)[0].description }}
                </p>
                <div class="font-title-btn text-left text-lg">{{ $t("phase4.person_" + person_id) }}:</div>
                <p id="testing" @change="checkTypeWriter" class="font-content">
                    {{ questionSelected == 0 ? "" : questions.filter((f) => f.id == questionSelected)[0].response }}
                </p>
            </div>
            <hr class="mt-1 mb-4 h-1 w-full bg-silver-light rounded-xl border-none" />
            <div class="font-title-btn text-left text-lg">{{ $t("phase4.questions") }}:</div>
            <div
                v-for="question in questions"
                :key="question"
                class="flex flex-col w-full mb-2"
                :class="question.clicked ? 'btn-answer' : 'button'"
            >
                <div class="flex flex-col items-center mx-4 text-center">
                    <div class="flex flex-row items-center text-lg text-left">
                        <div
                            class="font-content py-2"
                            @click="showQuestion(question.id)"
                            :class="blockedAnswer ? 'opacity-50 mouse cursor-not-allowed' : ''"
                        >
                            {{ question.description }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ActionBar
            :showValidate="false"
            :showNext="$store.state.storePhasesData.phase4Data.persons[person_id]"
            :pageBack="
                () => {
                    $router.go(-1);
                }
            "
        />
    </div>
</template>

<script>
import InfoCard from "@/components/cards/InfoCard.vue";
import ActionBar from "@/components/ActionBar.vue";
import { ref, nextTick } from "vue";
import typeWriter from "@/libs/typewriter.js";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";

let timeTyping = 5000;
export default {
    props: {},
    components: { InfoCard, ActionBar },

    setup(props) {
        const questionSelected = ref(0);
        const blockedAnswer = ref(false);
        const { t } = useI18n();
        const route = useRoute();
        const store = useStore();
        const questionsDB = ref({
            1: [
                {
                    id: 1,
                    description: t("phase4.question_A_1"),
                    response: t("phase4.response_A_1"),
                    completed: 0,
                    clicked: 0,
                },
                {
                    id: 2,
                    description: t("phase4.question_A_2"),
                    response: t("phase4.response_A_2"),
                    completed: 0,
                    clicked: 0,
                },
                {
                    id: 3,
                    description: t("phase4.question_A_3"),
                    response: t("phase4.response_A_3"),
                    completed: 0,
                    clicked: 0,
                },
            ],
            2: [
                {
                    id: 4,
                    description: t("phase4.question_B_1"),
                    response: t("phase4.response_B_1"),
                    completed: 0,
                    clicked: 0,
                },
                {
                    id: 5,
                    description: t("phase4.question_B_2"),
                    response: t("phase4.response_B_2"),
                    completed: 0,
                    clicked: 0,
                },
                {
                    id: 6,
                    description: t("phase4.question_B_3"),
                    response: t("phase4.response_B_3"),
                    completed: 0,
                    clicked: 0,
                },
            ],
            3: [
                {
                    id: 4,
                    description: t("phase4.question_C_1"),
                    response: t("phase4.response_C_1"),
                    completed: 0,
                    clicked: 0,
                },
                {
                    id: 5,
                    description: t("phase4.question_C_2"),
                    response: t("phase4.response_C_2"),
                    completed: 0,
                    clicked: 0,
                },
                {
                    id: 6,
                    description: t("phase4.question_C_3"),
                    response: t("phase4.response_C_3"),
                    completed: 0,
                    clicked: 0,
                },
            ],
        });
        const questions = ref(questionsDB.value[route.query.person_id]);
        const showQuestion = async (question_id) => {
            if (blockedAnswer.value) {
                return;
            }

            const question_index = questions.value.findIndex((obj) => obj.id == question_id);
            questions.value.forEach((el) => {
                el.clicked = 0;
            });
            questionSelected.value = question_id;

            await nextTick();
            if (!questions.value[question_index].completed) {
                questions.value[question_index].completed = 1;
                store.commit("phase4SetPersonCompleted", { questions: questions, person_id: route.query.person_id });
                checkTypeWriter();
            }
            questions.value[question_index].clicked = 1;
        };

        const checkTypeWriter = () => {
            typeWriter("testing", timeTyping);
            blockedAnswer.value = true;
            setTimeout(() => {
                blockedAnswer.value = false;
            }, timeTyping);
        };

        return {
            questions,
            showQuestion,
            questionSelected,
            checkTypeWriter,
            blockedAnswer,
            person_id: route.query.person_id,
        };
    },
};
</script>

<style lang="scss" scoped>
select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-image: url("@/assets/icons/sort-down-solid.svg");
    background-repeat: no-repeat, repeat;
    background-position: right 0.7em top 0, 0 0;
    background-size: 0.65em auto, 100%;
    @apply bg-silver-light;
}
.button {
    cursor: pointer;

    @apply rounded-md border-solid border-4 bg-silver-dark/30 border-silver-dark;
}
.btn-answer {
    @apply text-silver-dark bg-silver-dark rounded-md border-silver-light border-4 border-solid;
}
</style>

const ca = {
    general: {
        subtitle: "RESOL ELS 9 REPTES I GUANYA ACCÉS A LA SALA SECRETA DE L'EXPOSICIÓ",
        play: "COMENCEM",
        back: "ANTERIOR",
        forward: "SEGÜENT"
    },
    notifications: {
        phase_validated_title: "Molt bé!",
        phase_validated_description: "Has completat aquesta fase amb éxit. Segueix així!",
        phase_failed_title: "Gairebé ho tens...",
        phase_failed_description: "No has completat aquesta fase. Torna a intentar-ho!",
        phase_already_validated_title: "Ep! Torna enrere",
        phase_already_validated_description: "Aquesta fase ja estava completada.",
        register_ok: "T'has enregistrat correctament.",
        register_fail: "No t'has pogut enregistrar. Torna-ho a intentar.",
    },
    scan_qr: {
        title: "ESCANEIG DE QR"
    },
    action_bar: {
        back: "ENRERE",
        validate: "VALIDA",
        next: "SEGÜENT",
    },
    register: {
        description: "Enregistra't per començar a jugar.",
        name: "Nom",
        surname: "Cognoms",
        email: "Correu",
        zip_code: "Codi postal",
        terms_conditions: "He llegit els termes i condicions.",
        receive_information: "Vull rebre informació comercial.",
        access: "COMENCEM",
    },
    home: {
        code_access: "CODI D'ACCÉS A LA SALA SECRETA",
        code_access_unlocked: "PISTA PER ENTRAR A LA SALA SECRETA",
        code_access_date: "EN QUIN ANY ES VA ESTRENAR CRIMS A LA RADIO? RESPOSTA: C+ANY",
        map_locked: "MAPA DE LA SALA SECRETA",
        map_unlocked: "PREM PER OBRIR EL MAPA",
        map_showed: "FES SERVIR EL MAPA PER TROBAR LA SALA SECRETA",
    },
    phase1: {
        title: "1. ANAGRAMA",
        story: "Una de les habilitats més importants d'un investigador és parar atenció a qualsevol detall per petit que sigui. Fixa't en la primera sala de l'exposició, veus alguna cosa que et cridi l'atenció?",
        description: "Troba les lletres marcades i completa la frase seguint el següent codi de colors:",
    },
    phase2: {
        title: "2. FINESTRES",
        story: "Per entendre un assassí has de pensar com ell i ficar-te a la seva ment. Els crims poden tenir diferents detonants: motius econòmics, religiosos, morals, sexuals o simplement per pura violència. Per a la següent prova, fixa't en les finestres de la segona sala.",
        description: "Tot mirant les finestres, relaciona cada descripció amb el cas corresponent:",
        case_1: "IAIA ANITA",
        case_2: "ANGI",
        case_3: "ISIDRE",
        case_4: "MACHALA",
        case_5: "SONIA",
        case_6: "LA FLOR",
        case_7: "CA N'AMAT",
        case_1_description: "Fornera de barri, accedeix al domicili de les víctimes aprofitant la relació d’amistat que hi manté. Un cop dins, les distreu demanant un got d’aigua per a atacar-les per sorpresa. Un cop mortes, els hi roba diners i objectes de valor.",
        case_2_description: "Per mantenir el seu estil de vida, suplanta la identitat d’una companya de feina per contractar assegurances de vida i préstecs per valor de milers d’euros. Dos anys després, la mata per cobrar les assegurances contractades.",
        case_3_description: "Cansada del seu matrimoni i ofegada pels deutes que havia generat la seva addicció al bingo, encarrega a dos sicaris l’assassinat del seu marit per cobrar les assegurances de vida. Els delinqüents, però, són molt matussers i necessiten més d’un intent per posar fi a la vida de l'home.",
        case_4_description: "Aprofitant els diferents tipus de feines que té, vigila i assetja les seves víctimes. Un cop indefenses, les estrangula i agredeix sexualment. ",
        case_5_description: "Sis joves de Barcelona surten de “cacera”, buscant a on dipositar tota la seva ràbia, odi i agressivitat. Troben el seu objectiu dormint al Parc de la Ciutadella: dues dones transsexuals sense llar.",
        case_6_description: "A Colòmbia, un marit i pare de família, a Mongat, un immigrant homosexual tranquil i treballador. A la intimitat, un exmilitar violent d’ideologia neonazi. La seva víctima mortal representa tot allò que odia de si mateix: una persona immigrant, de color i homosexual.",
        case_7_description: "La relació entre germans i les seves parelles, que conviuen sota el mateix sostre, es tensa fins que una de les parelles decideix posar fi a la vida de l’altra. Inspirats en una famosa sèrie de televisió, esquarteren els cadàvers i els amaguen al congelador de la casa que comparteixen.",
        case: "A quin cas  correspon aquesta descripció?",
        t_description: "DESCRIPCIÓ",
        window_title: "CAS"
    },
    phase3: {
        title: "3. RETRATS ROBOT",
        story: "La ment ens pot jugar males passades: és molt possible que els records canviin, que les cares es difuminin i que, fins i tot, recordem coses que mai van arribar a passar d’aquella manera. Troba els sospitosos a la roda de reconeixement, els sabries identificar entre una pila de retrats robot? ",
        selected: "SELECCIONATS",
        description: "Selecciona els retrats robot de dos dels sospitosos que has vist a la roda de reconeixement de la sala 3:",
    },
    phase4: {
        title: "4. INTERROGATORI",
        story: "El pes del silenci es fa molt evident en els interrogatoris. Les persones implicades en els casos poden amagar informació durant anys i fer vida normal com si no hagués passat res. En aquesta prova us presentem un cas FICTICI, per tal que us poseu en el paper de l’entrevistador durant una llarga sessió d'interrogacions.",
        description: "Revisa les entrevistes de 3 persones relacionades amb el nostre cas FICTICI. <br>Amb aquesta informació, determina quines afirmacions són reals.",
        interrogations: "TRIA UNA PERSONA PER INTERROGAR:",
        what_know: "QUÈ SABEM?",
        person_empty: "Interroga a la persona per veure la seva declaració.",
        reconstruction: "RECONSTRUCCIÓ DELS FETS",
        description_contradiction: "Digues quines de les afirmacions següents són veritat:",
        plausible: "VERTADER",
        contradiction: "FALS",
        person_1: "Recepcionista",
        person_2: "Parella",
        person_3: "Companya Feina",
        person_3_br: "Companya<br>Feina",
        statement_1_A: "- La víctima i la seva companya de feina van a veure una exposició el vespre dels fets.",
        statement_1_B: "- La víctima anava ben mudada la nit dels fets.",
        statement_1_C: "- Troba el cadàver el matí següent al contenidor de davant del Palau.",
        statement_2_A: "- Era la parella de la víctima.",
        statement_2_B: "- L’últim cop que va veure la víctima va ser la tarda del dia dels fets.",
        statement_2_C: "- La víctima tenia problemes amb una companya de feina.",
        statement_3_A: "- Tenia una aventura amb la víctima.",
        statement_3_B: "- Sabia que la víctima tenia problemes amb la seva parella.",
        statement_3_C: "- Marxa sola del Palau Robert, no amb la víctima.",
        fact_1: "El recepcionista troba el cadàver el vespre dels fets.",
        fact_2: "La parella diu que la víctima tenia problemes amb una companya de feina.",
        fact_3: "La companya de feina sabia que la víctima tenia parella.",
        fact_4: "Segons la parella, la víctima va dormir a casa aquella nit.",
        fact_5: "Segons el recepcionista, la víctima anava ben mudada la nit dels fets.",
        fact_6: "La parella diu que la víctima va deixar la feina per problemes de salut.",
        question_A_1: "A quina hora vas trobar el cadàver?",
        question_A_2: "Coneixies a la víctima?",
        question_A_3: "Com anava vestida la víctima?",
        question_B_1: "Quina relació tenies amb la víctima?",
        question_B_2: "Saps si la víctima tenia algun enemic conegut?",
        question_B_3: "Quan et vas adonar de la seva desaparició?",
        question_C_1: "Quina relació tenies amb la víctima?",
        question_C_2: "Quan va ser l’última vegada que vas veure la víctima?",
        question_C_3: "Saps si tenia algun enemic conegut?",
        response_A_1: "Al matí quan tornava al Palau sobre les 8.30 vaig veure un peu que sortia d’una bossa a uns contenidors propers.",
        response_A_2: "El vespre anterior va venir a veure l’exposició amb una noia. Em va semblar que eren parella, van discutir molt però van marxar junts.",
        response_A_3: "Anaven ben mudats els dos... Ell duia pantalons texans i una camisa blanca.",
        response_B_1: "Érem parella des de feia 8 anys.",
        response_B_2: "Feia poc que havia deixat la feina perquè havia tingut problemes amb una companya… Ella estava obsesionada amb ell, no el deixava en pau.",
        response_B_3: "Aquella nit no va dormir a casa, no era normal en ell. Em vaig preocupar però com aquella tarda havíem discutit vaig pensar que potser era amb els amics. Al matí següent em va despertar la trucada de la policia per dir-me que havien trobat el seu cos sense vida.",
        response_C_1: "Érem companys de feina i estàvem enamorats... ell tenia parella des de feia anys però no se l’estimava, aquella nit m’havia promés que la deixaria en arribar a casa.",
        response_C_2: "Aquella tarda vam estar junts al Palau Robert. Després de veure l’exposició, ell va marxar a casa seva per acabar amb la seva relació. Va ser l'últim que vaig saber d'ell...",
        response_C_3: "Que jo sàpiga ningú... es portava bé amb tothom. La única qui causava problemes era la seva parella… De fet el va obligar a deixar la feina perquè estava gelosa de mi.",
        interrogation_suspect: "INTERROGATORI SOSPITÓS",
        interrogator: "INTERROGADOR",
        suspect: "SOSPITÓS",
        question: "PREGUNTA",
        response: "RESPOSTA",
        questions: "PREGUNTES",
    },
    phase5: {
        title: "5. CAPÍTOLS",
        story: "L'arribada de CRIMS a la televisió va ser tot un èxit. Amb el capítol de la Noia de Portbou, es van trencar els récords d'audiència. A la següent prova trobaràs el nom de 6 capítols, recordes en quin ordre es van emetre?",
        description: "Ordena els episodis segons la seva data d'emissió a la TV. Troba aquesta informació online o a la exposició:",
        facts_found: "DATES TROBADES",
        scan_next: "ESCANEJA <br>EL SEGÜENT",
        qr_1_title: "Brito i Picatoste",
        qr_2_title: "Machala",
        qr_3_title: "Ca n'Amat",
        qr_4_title: "Guàrdia Urbana",
        qr_5_title: "Iaia Anita",
        qr_6_title: "La noia de Portbou",
        qr_1_description: "Evi Rauter deixa un missatge al pis de la seva germana a Florencia que diu: “Me’n vaig a Siena.”",
        qr_2_description: "Una veïna de Portbou veu que hi ha una noia penjada d’un pi prop del cementiri. No porta cap mena d’identificació i el cas es refreda.",
        qr_3_description: "L’equip de CRIMS s’interessa per el cas i grava el primer episodi de “La noia de Portbou”.",
        qr_4_description: "Un canal de TV Austríac, gràcies a la col·laboració de l’equip de CRIMS, emet un episodi sobre “La noia de Portbou”. Una dona italiana de vacances a Àustria veu el capítol i identifica la noia.",
        qr_5_description: "L’equip de CRIMS rep un missatge de la TV Austríaca dient que han identificat la noia: “WE FOUND THE GIRL!” (“Hem trobat la noia!”)",
        qr_6_description: "S’emeten els capítols de CRIMS sobre “La noia de Portbou” a TV3, on podem veure que la família confirma la identitat de l’Evi Rauter, una jove italiana que portava més de 32 anys desapareguda.",
    },
    phase6: {
        title: "6. VIDEOVIGILÀNCIA",
        story: "Gràcies als avenços tecnològics, s’han pogut resoldre molts casos, fins i tot anys després dels fets. En aquest cas, estigues atent a les pantalles de les càmeres de videovigilància per identificar diversos objectes.",
        description: "Has vist aquests objectes a alguna de les càmeres de videovigilància? Ajuda't dels monitors per resoldre-ho:",
        cameras: "CÀMERES",
        name_1: "LLIBRETA VERMELLA",
        name_2: "GAT IAIA ANITA",
        name_3: "DVDS DEXTER",
        name_4: "PERRUCA MORENA",
        name_5: "DESTRAL",
        name_6: "BANDERA",
        name_7: "FLORS",
        name_8: "BOSSA ESPORT",
        yes: "SÍ",
        no: "NO",
    },
    phase7: {
        title: "7. ESCOLTA",
        story: "Les escoltes policials són una eina primordial pels investigadors, que passen hores escoltant converses per trobar algun detall que els pugui portar en la direcció correcta. Per resoldre aquesta prova, haureu de trobar uns telèfons i escoltar els seus missatges.",
        description: "Troba els telèfons que pengen del sostre i respon les següents preguntes segons el que escolteu:",
        question_1: "En una trucada intervinguda del cas Brito i Picatoste, dos homes parlen de pujar per una carretera, de quina carretera es tracta?",
        question_2: "El 112 rep una trucada anònima on una dona avisa que a una senyora que es diu _______  “li ha passat alguna cosa greu”. Com es diu la senyora?",
        question_3: "Quants diners va oferir la dona de l’Isidre per matar el seu marit?",
        /*question_4: "En una conversa entre l’Isaac i la seva amiga, ell diu “¿Siempre que te hablo de mi _____, te has de reír o qué?”. De què parla?",
        question_5: "En una trucada entre els assassins de l’Isidre, un d’ells diu que té una part del cos molt inflada. De quina part del cos parla?",*/
        response_1_A: "Tibidado",
        response_1_B: "Collserola",
        response_1_C: "Sant Cugat",
        response_1_D: "Carretera de les Aigües",
        response_2_A: "Maria",
        response_2_B: "Ana",
        response_2_C: "Concepció",
        response_2_D: "Encarna",
        response_3_A: "5 milions de pesetes",
        response_3_B: "7 milions de pesetes",
        response_3_C: "1 milio de pesetes",
        response_3_D: "700 mil pesetes",
        /*response_4_A: "casa",
        response_4_B: "madre",
        response_4_C: "novia",
        response_4_D: "pasión",
        response_5_A: "Llavi",
        response_5_B: "Genoll",
        response_5_C: "Mà",
        response_5_D: "Ulls",*/
    },
    phase8: {
        title: "8. DESXIFRAR",
        story: "En Brito i en Picatoste es van escapar d'una presó a Lleida deixant un rastre de sang per tota Catalunya. Es comunicaven amb diferents codis que, en teoria, havien de memoritzar i cremar. La policia va trobar la llegenda del seu codi secret i van poder desxifrar els seus missatges.",
        description: "Troba els 4 codis QR per aconseguir la clau dels codis dels pròfugs. <br>Quan els trobis, desxifra el següent missatge:",
        scan_qr: "ESCANEJA ELS QR PER DESXIFRAR EL CODI"
    },
    phase9: {
        title: "9. ESTADÍSTIQUES",
        story: "Tot i que a CRIMS us mostrem la part més fosca de la nostra societat, les xifres de delinqüència a Catalunya són molt baixes. Troba la pantalla del mapa delinqüencial de Catalunya per resoldre el següent enigma.",
        description: "Consulta la pantalla tàctil o accedeix al següent enllaç per respondre les següents preguntes:",
        question_1: "Quantes temptatives d’assassinat hi va haver a Catalunya l’any 2019?",
        question_2: "De les temptatives de la pregunta anterior, dues van succeir en un mateix mes. De quin mes es tracta?",
        question_3: "Quants casos de temptativa d’assassinat hi va haver a Catalunya el 2020?",
        question_4: "Quants assasinats consumats hi va haver a Catalunya l’any 2020?",
        access_web: "ACCEDEIX AL WEB",
    },
    cookies: {
        title: "Cookies",
        text_accept: "Aquesta web fa servir cookies tècniques. Si en vols saber més, fes ",
        click_here: "click aquí.",
        btn_accept: "OK",
        text: "Nuestra Política de Cookies tiene por finalidad informarle de forma clara y completa sobre el funcionamiento y finalidad de las cookies que se utilizan en nuestro Sitio Web. " +
            "En caso de que quiera recabar más información sobre las cookies, puede remitir un correo electrónico en tal sentido a zoopa{'@'}zoopa.es.<br><br>¿Qué es una 'cookie'?<br><br> Una cookie es un archivo que se descarga en su equipo terminal/dispositivo (ordenador o teléfono móvil) con la finalidad de almacenar datos que podrán ser actualizados y recuperados por la entidad responsable de su instalación. Nuestro Sitio web utiliza “cookies” con la finalidad de poder recabar información acerca de su funcionamiento y uso por los usuarios del mismo. Finalidad<br><br>Las cookies pueden recabar información sobre sus visitas a nuestro Sitio Web, la frecuencia y duración de las mismas, las páginas visionadas y el tiempo que ha estado en nuestro Sitio Web. " +
            "Le informamos de que podemos utilizar cookies con la finalidad de: -Proporcionarle una mejor experiencia en el uso del mismo.<br><br>Cookies utilizadas en la web<br><br>Nuestro Sitio Web utiliza cookies propias con diferentes finalidades, concretamente:<br><br> " +
            "Cookies propias<br><br>Son enviadas a su ordenador y gestionadas exclusivamente por nosotros para el mejor funcionamiento del Sitio Web. Estas cookies permanecen su navegador, permitiéndonos identificarle como usuario recurrente del Sitio Web y personalizar su contenido para ofrecerle contenidos ajustados a sus preferencias.<br></br>Cookies utilizadas en la web<br><br>Cookies técnicas<br><br>Las cookies técnicas son aquellas imprescindibles para el funcionamiento de la página web, como por ejemplo gestionar la autenticación o mantenimiento de una sesión de un usuario registrado en la página web. La desactivación de estas cookies puede impedir el correcto funcionamiento de la página.<br><br>Consentimiento<br><br>Al navegar y continuar en nuestro Sitio Web no estará consintiendo el uso de las cookies antes enunciadas, en las condiciones contenidas en la presente Política de Cookies. Es preciso aceptarlas expresamente o rechazarlas. Le informamos de que, dado que las cookies no son necesarias para navegar en nuestro Sitio Web, puede bloquearlas o deshabilitarlas a través de la configuración de su navegador. Si las bloquea o deshabilita podrá seguir usando nuestro Sitio Web, aunque el uso de algunos de sus servicios podrá ser limitado y por tanto su experiencia en nuestro Sitio Web menos satisfactoria. Si desea revocar su consentimiento informado respecto a nuestra política de cookies, deberá eliminarlas en su dispositivo a través de la configuración de sus navegadores de Internet. Estos son los enlaces de los distintos navegadores que informan sobre las cookies: Firefox Chrome Explorer Safari Opera Le recomendamos revisar esta política cada vez que acceda a nuestro Sitio Web con el objetivo de estar adecuadamente informado sobre posibles cambios en la misma.<br><br>",
    }
}

module.exports = {
    ca
}

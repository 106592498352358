const url = window.location.pathname


const phase1Data = {
    "word_1": "",
    "word_2": "",
    "word_3": "",
}

const phase1Mutations = {
    phase1SetResults(state, data) {
        if (typeof data.word_0 !== 'undefined') {
            data.word_0 = data.word_0.toLowerCase().normalize('NFKD').replace(/[^\w]/g, '');
        }
        data.word_1 = data.word_1.toLowerCase().normalize('NFKD').replace(/[^\w]/g, '');
        data.word_2 = data.word_2.toLowerCase().normalize('NFKD').replace(/[^\w]/g, '');
        data.word_3 = data.word_3.toLowerCase().normalize('NFKD').replace(/[^\w]/g, '');
        if (localStorage.language == 'en') {
            if (data.word_1 == 'show' && data.word_0 == 'we') {
                data.word_1 = 'mostrem'
            }
            if (data.word_2 == 'real') {
                data.word_2 = 'crims'
            }
            if (data.word_3 == 'crimes') {
                data.word_3 = 'reals'
            }
        } else if (localStorage.language == 'es') {
            if (data.word_1 == 'mostramos') {
                data.word_1 = 'mostrem'
            }
            if (data.word_2 == 'crimenes') {
                data.word_2 = 'crims'
            }
            if (data.word_3 == 'reales') {
                data.word_3 = 'reals'
            }
        }
        state.phase1Data.word_1 = data.word_1
        state.phase1Data.word_2 = data.word_2
        state.phase1Data.word_3 = data.word_3
    }
}

const phase1DataResults = ({ commit, state, rootState }, data) => {
    commit("phase1SetResults", data.val())
}

export { phase1Data, phase1DataResults, phase1Mutations }
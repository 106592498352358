<template>
    <div class="text-center justify-center items-center pb-2">
        <div class="text-left text-xl items-start justify-start">
            <PhaseHeader :title="$t('phase5.title')" :description="$t('phase5.description')" />
            <Draggable :list="facts" @end="saveOrder" item-key="id" ghost-class="ghost" class="list-group">
                <template #item="{ element }">
                    <div class="flex flex-col items-center list-group-item">
                        <InfoCard :fact="element" />
                        <inline-svg
                            v-if="facts[5].id != element.id"
                            :src="require('@/assets/icons/angle-down-solid.svg')"
                            alt="Mapa"
                            class="w-12 h-12 fill-silver-dark"
                        />
                    </div>
                </template>
            </Draggable>
        </div>
        <!--<div class="flex fixed mb-16 bottom-0 justify-center items-center text-center w-full left-0 result">
            <div class="flex flex-col font-title-btn items-center text-lg text-center gap-2">
                <div class="flex flex-row">
                    <span class="font-cta">{{ facts.filter((f) => f.found == true).length }}</span>
                    /{{ facts.length }} {{ $t("phase5.facts_found") }}
                </div>
                <div @click="scanQR" class="flex flex-row items-center justify-center gap-4 button p-1 px-6">
                    <div class="flex font-title-btn" v-html="$t('phase5.scan_next')"></div>
                    <inline-svg
                        :src="require('@/assets/icons/camera-solid.svg')"
                        alt="Validar prova"
                        class="w-12 h-12 fill-silver-light"
                    />
                </div>
            </div>
        </div>-->
    </div>
</template>

<script>
import PhaseHeader from "@/components/PhaseHeader.vue";
import InfoCard from "@/components/cards/InfoCard.vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import shuffleArray from "@/libs/utils.js";
import Draggable from "vuedraggable";
import { ref, onMounted } from "vue";

export default {
    props: {},
    components: { InfoCard, PhaseHeader, Draggable },

    setup(props) {
        const store = useStore();
        const router = useRouter();
        const { t } = useI18n();
        //for test validate
        //store.state.storePhasesData.phase5Data.factsScanned = [5, 1, 3, 4, 2, 7, 6];
        const facts = ref([
            {
                id: 1,
                title: t("phase5.qr_1_title"),
                description: t("phase5.qr_1_description"),
            },
            {
                id: 2,
                title: t("phase5.qr_2_title"),
                description: t("phase5.qr_2_description"),
            },
            {
                id: 3,
                title: t("phase5.qr_3_title"),
                description: t("phase5.qr_3_description"),
            },
            {
                id: 4,
                title: t("phase5.qr_4_title"),
                description: t("phase5.qr_4_description"),
            },
            {
                id: 5,
                title: t("phase5.qr_5_title"),
                description: t("phase5.qr_5_description"),
            },
            {
                id: 6,
                title: t("phase5.qr_6_title"),
                description: t("phase5.qr_6_description"),
            },
        ]);
        shuffleArray(facts);
        const createValidateArray = () => {
            var arr = [];
            facts.value.forEach((fact) => {
                arr.push(fact.id);
            });
            return arr;
        };
        onMounted(() => {
            shuffleArray(facts.value);
            store.commit("phase5SetResults", { facts: createValidateArray() });
        });

        const saveOrder = () => {
            console.log(saveOrder);
            store.commit("phase5SetResults", { facts: createValidateArray() });
        };

        /*const scanQR = () => {
            router.push({ name: "scanQR", query: { phase: 5 } });
        };*/

        return { facts, /*scanQR,*/ saveOrder };
    },
};
</script>

<style lang="scss" scoped>
.result {
    background-color: rgb(29 32 41) !important;
    @apply h-28;
}
.button {
    cursor: pointer;

    @apply bg-silver-dark rounded-md;
}
.ghost {
    opacity: 0.5;
    background: #c8ebfb;
}
</style>

const url = window.location.pathname


const phase8Data = {
    answers: "",
    qr_scanned: []
}

const phase8Mutations = {
    phase8SetResults(state, data) {
        state.phase8Data.answers = createPhrase(data.value)
    },
    phase8SetQRScanned(state, data) {
        console.log(data)
        if (!state.phase8Data.qr_scanned.includes(data.code)) {
            state.phase8Data.qr_scanned.push(data.code)
        }
    }
}

const phase8DataResults = ({ commit, state, rootState }, data) => {
    commit("phase8SetResults", data.val())
}

const phase8QRScanned = ({ commit, state, rootState }, data) => {
    commit("phase8SetQRScanned", data.val())
}

const createPhrase = (data) => {
    var finalPhrase = "";
    data.forEach(word => {
        finalPhrase += word.word
        finalPhrase += " "
    });
    finalPhrase = finalPhrase.toUpperCase().trim()
    return finalPhrase;
}
export { phase8Data, phase8DataResults, phase8Mutations, phase8QRScanned }
import { createStore } from 'vuex'
import storePhasesData from './storePhasesData.js'
import storeGameData from './storeGameData.js'
const store = createStore({
  modules: {
    storeGameData,
    storePhasesData
  }
})

export function useStore() {
  return store
}

export default store

<template>
    <div class="text-center justify-center items-center pb-2">
        <div class="text-left text-xl items-start justify-start">
            <PhaseHeader :title="$t('phase9.title')" :description="$t('phase9.description')" />
            <div class="flex flex-col items-center mb-2">
                <div @click="goWeb" class="flex flex-row items-center w-11/12 justify-center button p-2">
                    <div class="flex font-title-btn text-sm">{{ $t("phase9.access_web") }}</div>
                    <inline-svg
                        :src="require('@/assets/icons/link-solid.svg')"
                        alt="accedir al web"
                        class="w-12 h-6 fill-silver-light"
                    />
                </div>
            </div>
            <div
                class="flex flex-col p-3 rounded-md border-solid border-3 bg-silver-dark/30 border-silver-dark mb-2 items-center"
                v-for="question in questions"
                :key="question"
            >
                <div class="font-content mb-2">{{ question.text }}</div>
                <input
                    v-if="question.type == 'number'"
                    @blur="saveAnswer($event, question.id)"
                    type="number"
                    pattern="[0-9]*"
                    inputmode="numeric"
                    class="font-input w-4/12 text-center"
                    :value="question.answer"
                />
                <input
                    v-else
                    @blur="saveAnswer($event, question.id)"
                    type="text"
                    class="font-input w-4/12 text-center"
                    :value="question.answer"
                />
            </div>
        </div>
    </div>
</template>

<script>
import PhaseHeader from "@/components/PhaseHeader.vue";
import { ref } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
export default {
    props: {},
    components: { PhaseHeader },

    setup(props) {
        const store = useStore();
        const { t } = useI18n();
        const questions = ref([
            {
                id: 1,
                text: t("phase9.question_1"),
                answer: store.state.storePhasesData.phase9Data.answers[1]
                    ? store.state.storePhasesData.phase9Data.answers[1]
                    : "",
                type: "number",
            },
            {
                id: 2,
                text: t("phase9.question_2"),
                answer: store.state.storePhasesData.phase9Data.answers[2]
                    ? store.state.storePhasesData.phase9Data.answers[2]
                    : "",
                type: "text",
            },
            {
                id: 3,
                text: t("phase9.question_3"),
                answer: store.state.storePhasesData.phase9Data.answers[3]
                    ? store.state.storePhasesData.phase9Data.answers[3]
                    : "",
                type: "number",
            },
            {
                id: 4,
                text: t("phase9.question_4"),
                answer: store.state.storePhasesData.phase9Data.answers[4]
                    ? store.state.storePhasesData.phase9Data.answers[4]
                    : "",
                type: "number",
            },
        ]);
        const goWeb = () => {
            window.open("https://visors.icgc.cat/mapa-delinquencial/");
        };
        const saveAnswer = (event, question_id) => {
            const answer = event.target.value;
            const question_index = questions.value.findIndex((obj) => obj.id == question_id);
            questions.value[question_index].answer = answer;
            store.commit("phase9SetResults", { question_id: question_id, answer: answer });
        };

        return { questions, saveAnswer, goWeb };
    },
};
</script>

<style lang="scss" scoped>
input {
    font-size: 12px;
    @apply rounded-md bg-silver-light h-8 px-2;
}
.button {
    cursor: pointer;

    @apply bg-silver-dark rounded-md;
}
.font-content {
    font-size: 18px;
}
</style>

<template>
    <div v-if="cookiesOk != 'OK'" class="flex flex-col items-center justify-center">
        <div
            class="flex flex-row py-2 cookies-banner bg-silver-dark rounded-xl w-11/12 justify-center items-center mb-2 font-title-btn border-2 border-silver-light border-solid"
        >
            <p class="text-silver-light w-11/12">
                {{ $t("cookies.text_accept") }}
                <a @click="cookiesPage">{{ $t("cookies.click_here") }}</a>
            </p>
            <div
                class="button text-silver-light border-4 border-gold-light border-solid rounded-xl px-4 text-center justify-center align-middle w-1/12 mr-2"
                @click="okCookies"
            >
                <p>{{ $t("cookies.btn_accept") }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import ActionBar from "@/components/ActionBar.vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { ref, onMounted } from "vue";
import { useI18n } from "vue-i18n";

export default {
    components: { ActionBar },
    props: {},
    setup(props) {
        const route = useRoute();
        const router = useRouter();
        const store = useStore();
        const { t } = useI18n();
        const cookiesOk = ref("");
        onMounted(() => {
            cookiesOk.value = localStorage.cookies;
        });
        const okCookies = () => {
            localStorage.cookies = "OK";
            cookiesOk.value = "OK";
        };

        const cookiesPage = () => {
            router.push("/cookies");
        };

        return { okCookies, cookiesOk, cookiesPage };
    },
};
</script>

<style scoped>
.cookies-banner {
    position: fixed;
    bottom: 0;
    font-size: 15px;
    z-index: 1000;
}
.button {
    cursor: pointer;
    border-radius: 3px;

    @apply bg-silver-dark;
}
.font-title-btn {
    font-size: 12px;
}
a {
    background-color: transparent;
    text-decoration: none;
    @apply text-gold-light;
}
a:visited {
    background-color: transparent;
    text-decoration: none;
    @apply text-gold-light/75;
}
a:hover {
    background-color: transparent;
    text-decoration: underline;
    @apply text-gold-light/75;
}
a:active {
    background-color: transparent;
    text-decoration: underline;
    @apply text-gold-light/75;
}
</style>

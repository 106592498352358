<template>
    <div class="interrogation">
        <Interrogation />
    </div>
</template>

<script>
// @ is an alias to /src

import Interrogation from "@/components/phases/Phase_4_Interrogation.vue";
import { useRouter } from "vue-router";
import { onMounted } from "vue";

export default {
    name: "InterrogationView",
    components: {
        Interrogation,
    },
    setup(props) {
        const router = useRouter();

        onMounted(() => {
            if (!localStorage.uuid) {
                router.push("/language");
            }
        });

        return {};
    },
};
</script>

<template>
    <div class="text-center justify-center items-center pb-2">
        <div v-if="personSelected <= 0 || personSelected >= 4" class="text-left text-xl items-start justify-start">
            <PhaseHeader :title="$t('phase4.title')" :description="$t('phase4.description')" />
            <div class="font-title-btn font-title-btn-size text-center mb-4">{{ $t("phase4.interrogations") }}</div>
            <div class="flex flex-col justify-center items-center gap-4">
                <div class="flex flex-col items-center text-center">
                    <div class="flex flex-row icon-box relative" @click="selectPerson(persons[0].id)">
                        <inline-svg
                            v-if="persons[0].completed"
                            :src="require('@/assets/icons/check-circle-solid.svg')"
                            :class="persons[0].completed ? 'icon-completed' : ''"
                        />
                        <div>
                            <inline-svg
                                :src="require('@/assets/icons/user-solid.svg')"
                                alt="Mapa"
                                class="w-16 h-14 fill-silver-light"
                            />
                        </div>
                    </div>
                    <div class="flex text font-phase-description text-sm mt-2">{{ $t("phase4.person_1") }}</div>
                </div>
                <div class="flex gap-4 flex-row">
                    <div class="flex flex-col items-center text-center">
                        <div class="flex flex-col icon-box relative" @click="selectPerson(persons[1].id)">
                            <inline-svg
                                v-if="persons[1].completed"
                                :src="require('@/assets/icons/check-circle-solid.svg')"
                                :class="persons[1].completed ? 'icon-completed' : ''"
                            />
                            <inline-svg
                                :src="require('@/assets/icons/user-solid.svg')"
                                alt="user"
                                class="w-16 h-14 fill-silver-light"
                            />
                        </div>
                        <div class="flex text font-phase-description text-sm mt-2">{{ $t("phase4.person_2") }}</div>
                    </div>
                    <div class="flex flex-col icon-box opacity-0"></div>
                    <div class="flex flex-col items-center text-center">
                        <div class="flex flex-col icon-box relative" @click="selectPerson(persons[2].id)">
                            <inline-svg
                                v-if="persons[2].completed"
                                :src="require('@/assets/icons/check-circle-solid.svg')"
                                :class="persons[2].completed ? 'icon-completed' : ''"
                            />
                            <inline-svg
                                :src="require('@/assets/icons/user-solid.svg')"
                                alt="Mapa"
                                class="w-16 h-14 fill-silver-light"
                            />
                        </div>
                        <div class="flex text font-phase-description text-sm mt-2" v-html="$t('phase4.person_3_br')" />
                    </div>
                </div>
            </div>
            <!--<hr class="my-4 h-1 bg-silver-light rounded-xl border-none" />
            <div class="font-title-btn font-title-btn-size text-center mb-4">{{ $t("phase4.what_know") }}</div>
            <UserCards
                :name="person.name"
                :id="person.id"
                :showId="showIdPerson"
                v-for="person in persons"
                :key="person"
                :changeView="setShowIdPerson"
            >
                <div v-if="person.completed" class="flex flex-col text-left mb-2 font-content">
                    <div class="w-11/12" v-for="statement in person.statements" :key="statement">{{ statement }}</div>
                </div>
                <div v-else class="flex flex-col text-left mb-2 font-content">
                    <div class="w-11/12">{{ $t("phase4.person_empty") }}</div>
                </div>
            </UserCards>-->
            <hr class="my-4 h-1 bg-silver-light rounded-xl border-none" />
            <div class="font-title-btn font-title-btn-size text-center mb-4">{{ $t("phase4.reconstruction") }}</div>
            <div class="font-phase-description text-lg mx-3 mb-2">
                {{ $t("phase4.description_contradiction") }}
            </div>
            <div
                class="flex flex-col p-3 rounded-md border-solid border-3 bg-silver-dark/30 border-silver-dark mb-2"
                v-for="fact in facts"
                :key="fact"
            >
                <div class="font-content mb-2">{{ fact.description }}</div>
                <div class="grid grid-cols-2 justify-center text-center">
                    <div
                        :class="fact.contradiction == 1 ? 'btn-fact mx-1' : 'btn-fact-outlined mx-1'"
                        class="font-title-btn"
                        @click="updateFactAnswer(fact.id, 1)"
                    >
                        {{ $t("phase4.plausible") }}
                    </div>
                    <div
                        :class="fact.contradiction == 2 ? 'btn-fact mx-1' : 'btn-fact-outlined mx-1'"
                        class="font-title-btn"
                        @click="updateFactAnswer(fact.id, 2)"
                    >
                        {{ $t("phase4.contradiction") }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PhaseHeader from "@/components/PhaseHeader.vue";
import UserCards from "@/components/cards/UserCards.vue";
import { ref, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
export default {
    props: {},
    components: { UserCards, PhaseHeader },

    setup(props) {
        const showIdPerson = ref(1);
        const personSelected = ref(0);
        const router = useRouter();
        const { t } = useI18n();

        const store = useStore();
        const persons = ref([
            {
                id: 1,
                completed: store.state.storePhasesData.phase4Data.persons[1],
                name: t("phase4.person_1"),
                statements: [t("phase4.statement_1_A"), t("phase4.statement_1_B"), t("phase4.statement_1_C")],
            },
            {
                id: 2,
                completed: store.state.storePhasesData.phase4Data.persons[2],
                name: t("phase4.person_2"),
                statements: [t("phase4.statement_2_A"), t("phase4.statement_2_B"), t("phase4.statement_2_C")],
            },
            {
                id: 3,
                completed: store.state.storePhasesData.phase4Data.persons[3],
                name: t("phase4.person_3"),
                statements: [t("phase4.statement_3_A"), t("phase4.statement_3_B"), t("phase4.statement_3_C")],
            },
        ]);
        const facts = ref([
            {
                id: 1,
                contradiction: store.state.storePhasesData.phase4Data.statements[1]
                    ? store.state.storePhasesData.phase4Data.statements[1]
                    : 0,
                description: t("phase4.fact_1"),
            },
            {
                id: 2,
                contradiction: store.state.storePhasesData.phase4Data.statements[2]
                    ? store.state.storePhasesData.phase4Data.statements[2]
                    : 0,
                description: t("phase4.fact_2"),
            },
            {
                id: 3,
                contradiction: store.state.storePhasesData.phase4Data.statements[3]
                    ? store.state.storePhasesData.phase4Data.statements[3]
                    : 0,
                description: t("phase4.fact_3"),
            },
            {
                id: 4,
                contradiction: store.state.storePhasesData.phase4Data.statements[4]
                    ? store.state.storePhasesData.phase4Data.statements[4]
                    : 0,
                description: t("phase4.fact_4"),
            },
            {
                id: 5,
                contradiction: store.state.storePhasesData.phase4Data.statements[5]
                    ? store.state.storePhasesData.phase4Data.statements[5]
                    : 0,
                description: t("phase4.fact_5"),
            },
            {
                id: 6,
                contradiction: store.state.storePhasesData.phase4Data.statements[5]
                    ? store.state.storePhasesData.phase4Data.statements[5]
                    : 0,
                description: t("phase4.fact_6"),
            },
        ]);

        const selectPerson = (person_id) => {
            router.push({ path: "/interrogation", query: { person_id: person_id } });
        };

        const updateFactAnswer = (id, value) => {
            const fact_index = facts.value.findIndex((obj) => obj.id == id);
            facts.value[fact_index].contradiction = value;
            store.commit("phase4SetResults", { statement_id: id, contradiction: value });
        };

        const setShowIdPerson = (action) => {
            if (action) {
                if (showIdPerson.value == persons.value.length) {
                    showIdPerson.value = 1;
                } else {
                    showIdPerson.value++;
                }
            } else {
                if (showIdPerson.value == 1) {
                    showIdPerson.value = persons.value.length;
                } else {
                    showIdPerson.value--;
                }
            }
        };

        return { facts, updateFactAnswer, persons, setShowIdPerson, showIdPerson, selectPerson, personSelected };
    },
};
</script>

<style lang="scss" scoped>
.icon-box {
    font-size: 22px;
    cursor: pointer;
    border-radius: 15px;
    width: 90px;
    height: 90px;

    @apply flex flex-row justify-center items-center bg-silver-dark;
}
.font-title-btn-size {
    font-size: 16px;
}

.btn-fact {
    font-size: 10px;
    @apply bg-silver-light text-silver-dark border-2 border-solid border-silver-light rounded-md;
}
.btn-fact-outlined {
    font-size: 10px;
    @apply bg-transparent border-2 border-solid border-silver-light rounded-md;
}
.icon-completed {
    position: absolute;
    width: 25px;
    height: 25px;
    bottom: 5px;
    right: 5px;
    @apply fill-silver-light;
}
</style>

const url = window.location.pathname


const phase9Data = {
    answers: {}
}

const phase9Mutations = {
    phase9SetResults(state, data) {
        var answer = data.answer.toLowerCase().normalize('NFKD').replace(/[^\w]/g, '');
        if (isNaN(answer)) {
            if (answer == 'october') {
                answer = 'octubre';
            }
        }

        state.phase9Data.answers[data.question_id] = answer;

    }
}

const phase9DataResults = ({ commit, state, rootState }, data) => {
    commit("phase9SetResults", data.val())
}

export { phase9Data, phase9DataResults, phase9Mutations }
const url = window.location.pathname


const phase3Data = {
    answers: []
}

const phase3Mutations = {
    phase3SetResults(state, data) {
        state.phase3Data.answers = data.images.value
    }
}

const phase3DataResults = ({ commit, state, rootState }, data) => {
    commit("phase3SetResults", data.val())
}

export { phase3Data, phase3DataResults, phase3Mutations }
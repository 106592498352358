const url = window.location.pathname




const storePhases = {
    state() {

        return {
            phasesValidated: [],
            codeOpened: false,
            mapOpened: false,
        }
    },

    mutations: {
        setPhasesValidated(state, data) {
            state.phasesValidated[data.phase_id - 1] = data.validated;
            localStorage.phasesValidated = state.phasesValidated;
        },
        setOpenCode(state, data) {
            state.codeOpened = true;
            localStorage.codeOpened = true;
        },
        setOpenMap(state, data) {
            if (state.mapOpened) {
                state.mapOpened = false;
                localStorage.mapOpened = false;
            }
            else {
                state.mapOpened = true;
                localStorage.mapOpened = true;
            }
        },
        recoverStoreGameData(state, data) {
            if (localStorage.phasesValidated) {
                var aux = localStorage.phasesValidated.split(',')
                state.phasesValidated = aux.map(e => e === 'true');
            }
            if (localStorage.codeOpened) {
                state.codeOpened = localStorage.codeOpened;
            }
            if (localStorage.mapOpened) {
                state.mapOpened = localStorage.mapOpened;
            }
        }
    },
    actions: {

    }


}

export default storePhases
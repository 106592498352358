<template>
    <div class="home" :class="register_success ? '' : 'bg-white'">
        <Home v-if="register_success" />
    </div>
</template>

<script>
// @ is an alias to /src

import Home from "@/components/Home.vue";
import { useRouter } from "vue-router";
import { ref, onMounted, onBeforeMount } from "vue";
import { axiosToken } from "../libs/http";

export default {
    name: "HomeView",
    components: {
        Home,
    },
    setup(props) {
        const router = useRouter();

        onMounted(() => {
            if (!localStorage.uuid) {
                router.push("/language");
            }
        });

        const register_success = ref(false);
        onBeforeMount(() => {
            if (!localStorage.register_success) {
                axiosToken({
                    method: "put",
                    url: "qr_scanned",
                }).then((response) => {
                    alert(response);
                });
                window.location.replace("https://www.ccma.cat/crims-exposicio-joc");
            }
            register_success.value = localStorage.register_success;
        });

        return { register_success };
    },
};
</script>

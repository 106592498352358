<template>
    <div class="text-center justify-center items-center pb-2">
        <div class="text-left text-xl items-start justify-start">
            <PhaseHeader :title="$t('phase7.title')" :description="$t('phase7.description')" />
            <div
                class="flex flex-col p-3 rounded-md border-solid border-3 bg-silver-dark/30 border-silver-dark mb-2"
                v-for="question in questions"
                :key="question"
            >
                <div class="font-content mb-2 text-sm">{{ question.text }}</div>
                <div class="grid grid-cols-2 text-center gap-2">
                    <div
                        v-for="answer in question.answers"
                        :key="answer"
                        :class="answer.id == question.answer ? 'btn-answer' : 'btn-answer-outlined'"
                        class="font-title-btn flex flex-row justify-center items-center py-1 px-1"
                        @click="updateAnswer(question.id, answer.id)"
                    >
                        {{ answer.text }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PhaseHeader from "@/components/PhaseHeader.vue";
import { ref } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
export default {
    props: {},
    components: { PhaseHeader },

    setup(props) {
        const store = useStore();
        const { t } = useI18n();
        const questions = ref([
            {
                id: 1,
                text: t("phase7.question_1"),
                answers: [
                    { id: 1, text: t("phase7.response_1_A") },
                    { id: 2, text: t("phase7.response_1_B") },
                    { id: 3, text: t("phase7.response_1_C") },
                    { id: 4, text: t("phase7.response_1_D") },
                ],
                answer: store.state.storePhasesData.phase7Data.answers[1]
                    ? store.state.storePhasesData.phase7Data.answers[1]
                    : 0,
            },
            {
                id: 2,
                text: t("phase7.question_2"),
                answers: [
                    { id: 1, text: t("phase7.response_2_A") },
                    { id: 2, text: t("phase7.response_2_B") },
                    { id: 3, text: t("phase7.response_2_C") },
                    { id: 4, text: t("phase7.response_2_D") },
                ],
                answer: store.state.storePhasesData.phase7Data.answers[2]
                    ? store.state.storePhasesData.phase7Data.answers[2]
                    : 0,
            },
            {
                id: 3,
                text: t("phase7.question_3"),
                answers: [
                    { id: 1, text: t("phase7.response_3_A") },
                    { id: 2, text: t("phase7.response_3_B") },
                    { id: 3, text: t("phase7.response_3_C") },
                    { id: 4, text: t("phase7.response_3_D") },
                ],
                answer: store.state.storePhasesData.phase7Data.answers[3]
                    ? store.state.storePhasesData.phase7Data.answers[3]
                    : 0,
            },
            /*{
                id: 4,
                text: t("phase7.question_4"),
                answers: [
                    { id: 1, text: t("phase7.response_4_A") },
                    { id: 2, text: t("phase7.response_4_B") },
                    { id: 3, text: t("phase7.response_4_C") },
                    { id: 4, text: t("phase7.response_4_D") },
                ],
                answer: store.state.storePhasesData.phase7Data.answers[4]
                    ? store.state.storePhasesData.phase7Data.answers[4]
                    : 0,
            },
            {
                id: 5,
                text: t("phase7.question_5"),
                answers: [
                    { id: 1, text: t("phase7.response_5_A") },
                    { id: 2, text: t("phase7.response_5_B") },
                    { id: 3, text: t("phase7.response_5_C") },
                    { id: 4, text: t("phase7.response_5_D") },
                ],
                answer: store.state.storePhasesData.phase7Data.answers[4]
                    ? store.state.storePhasesData.phase7Data.answers[4]
                    : 0,
            },*/
        ]);

        const updateAnswer = (question_id, answer_id) => {
            const question_index = questions.value.findIndex((obj) => obj.id == question_id);
            questions.value[question_index].answer = answer_id;
            store.commit("phase7SetResults", { question_id: question_id, answer_id: answer_id });
        };

        return { questions, updateAnswer };
    },
};
</script>

<style lang="scss" scoped>
.btn-answer {
    font-size: 10px;
    line-height: 1.5;
    @apply bg-silver-light text-silver-dark border-2 border-solid border-silver-light rounded-md;
}
.btn-answer-outlined {
    font-size: 10px;
    line-height: 1.5;
    @apply bg-transparent border-2 border-solid border-silver-light rounded-md;
}
</style>

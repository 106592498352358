<template>
    <div>
        <div class="font-cta my-4 text-2xl text-left" v-html="$t('cookies.title')" />
        <hr class="mb-4 h-1 bg-silver-light rounded-xl border-none" />
        <div class="flex flex-col text-silver-light text-justify mx-2" v-html="$t('cookies.text')"></div>
        <ActionBar :showValidate="false" />
    </div>
</template>

<script>
// @ is an alias to /src

import { useRouter } from "vue-router";
import { onMounted } from "vue";
import ActionBar from "@/components/ActionBar.vue";

export default {
    name: "CookiesView",
    components: { ActionBar },
    setup(props) {
        const router = useRouter();

        return {};
    },
};
</script>

<template>
    <div class="flex flex-col" v-if="id == showId">
        <div
            class="flex flex-col text-center bg-silver-dark/30 py-1 items-center rounded-md border-solid border-4 border-silver-dark"
        >
            <div class="flex flex-row gap-4 items-center mt-2 text-3xl">
                <inline-svg
                    :src="require('@/assets/icons/user-solid.svg')"
                    alt="User icon"
                    class="w-6 h-6 fill-silver-light"
                />
                <div class="font-title-btn title-text">{{ name }} {{ total ? id + "/" + total : "" }}</div>
            </div>
            <hr class="mb-4 w-11/12 h-1 bg-silver-dark rounded-xl border-none" />
            <div class="flex flex-col ml-8 w-full">
                <slot></slot>
            </div>
        </div>
        <div class="grid grid-cols-2 text-lg gap-4">
            <div class="flex flex-row button my-3 justify-center items-center p-2">
                <inline-svg
                    :src="require('@/assets/icons/caret-left-solid.svg')"
                    alt="Persona anterior"
                    class="w-8 h-8 fill-silver-light"
                />
                <div class="font-title-btn text-sm" @click="changeView(0)">{{ $t("general.back") }}</div>
            </div>
            <div class="flex flex-row button my-3 text-sm justify-center items-center p-2 right-0">
                <div class="font-title-btn" @click="changeView(1)">{{ $t("general.forward") }}</div>
                <inline-svg
                    :src="require('@/assets/icons/caret-right-solid.svg')"
                    alt="Persona següent"
                    class="w-8 h-8 fill-silver-light"
                />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        id: { type: Number, default: 0 },
        name: { type: String, default: "" },
        showId: { type: Number, default: 0 },
        total: { type: Number, default: 0 },
        changeView: {
            type: Function,
            default: (action) => {
                console.log(action);
            },
        },
    },
    components: {},

    setup(props) {
        return {};
    },
};
</script>

<style lang="scss" scoped>
.button {
    cursor: pointer;

    @apply bg-silver-dark rounded-md;
}
.title-text {
    font-size: 14px;
}
</style>

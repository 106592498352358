import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import GameView from '../views/GameView.vue'
import ScanQRView from '../views/ScanQRView.vue'
//import RegisterView from '../views/RegisterView.vue'
import StoryView from '../views/StoryView.vue'
import LanguageView from '../views/LanguageView.vue'
import InterrogationView from '../views/InterrogationView.vue'
import CookiesView from '../views/CookiesView.vue'
import SuccessView from '../views/SuccessView.vue'
import SolveAllPhases from '../views/SolveAllPhasesView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/game',
    name: 'game',
    component: GameView
  },
  {
    path: '/story',
    name: 'story',
    component: StoryView
  },
  {
    path: '/scanQR',
    name: 'scanQR',
    component: ScanQRView
  },
  /*{
    path: '/register',
    name: 'register',
    component: RegisterView
  },*/
  {
    path: '/language',
    name: 'language',
    component: LanguageView
  },
  {
    path: '/interrogation',
    name: 'interrogation',
    component: InterrogationView
  },
  {
    path: '/cookies',
    name: 'cookies',
    component: CookiesView
  },
  {
    path: '/success',
    name: 'success',
    component: SuccessView
  },
  {
    path: '/solve_all_phases',
    name: 'solve_all_phases',
    component: SolveAllPhases
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'home',
    component: HomeView
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router

const url = window.location.pathname


const phase7Data = {
    answers: {}
}

const phase7Mutations = {
    phase7SetResults(state, data) {
        state.phase7Data.answers[data.question_id] = data.answer_id;
    }
}

const phase7DataResults = ({ commit, state, rootState }, data) => {
    commit("phase7SetResults", data.val())
}

export { phase7Data, phase7DataResults, phase7Mutations }
import { phase1Data, phase1DataResults, phase1Mutations } from './phases/phase_1'
import { phase2Data, phase2DataResults, phase2Mutations } from './phases/phase_2'
import { phase3Data, phase3DataResults, phase3Mutations } from './phases/phase_3'
import { phase4Data, phase4DataResults, phase4Mutations } from './phases/phase_4'
import { phase5Data, phase5DataResults, phase5Mutations } from './phases/phase_5'
import { phase6Data, phase6DataResults, phase6Mutations } from './phases/phase_6'
import { phase7Data, phase7DataResults, phase7Mutations } from './phases/phase_7'
import { phase8Data, phase8DataResults, phase8Mutations } from './phases/phase_8'
import { phase9Data, phase9DataResults, phase9Mutations } from './phases/phase_9'

const url = window.location.pathname




const storePhases = {
    state() {

        // set all data from all phases
        return {
            phase1Data,
            phase2Data,
            phase3Data,
            phase4Data,
            phase5Data,
            phase6Data,
            phase7Data,
            phase8Data,
            phase9Data,

        }
    },

    mutations: {
        ...phase1Mutations,
        ...phase2Mutations,
        ...phase3Mutations,
        ...phase4Mutations,
        ...phase5Mutations,
        ...phase6Mutations,
        ...phase7Mutations,
        ...phase8Mutations,
        ...phase9Mutations

    },
    actions: {
    }


}

export default storePhases
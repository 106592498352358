<template>
    <div class="text-center justify-center items-center mb-44">
        <div class="text-left text-xl items-start justify-start">
            <PhaseHeader :title="$t('phase3.title')" :description="$t('phase3.description')" />
        </div>
        <div class="grid grid-cols-2 gap-y-4 place-items-center left-0">
            <div
                class="flex flex-row h-36 w-32 justify-center"
                @click="selectImage(image.id, image.set)"
                v-for="image in images.filter((img) => img.set == showImageSet)"
                :key="image"
            >
                <img
                    :src="image.src"
                    alt="test"
                    class="rounded-md"
                    :class="imagesSelected[image.set] == image.id ? 'suspect-highlight' : 'suspect'"
                />
            </div>
        </div>
        <div
            class="flex flex-col fixed mb-16 bottom-0 justify-center items-center text-center w-full left-0 result bg-blue-dark"
        >
            <div class="flex flex-row gap-6">
                <div class="flex flex-row button my-3 justify-center items-center p-2 bg-silver-dark rounded-md">
                    <inline-svg
                        :src="require('@/assets/icons/caret-left-solid.svg')"
                        alt="Persona anterior"
                        class="w-8 h-8 fill-silver-light"
                    />
                    <div class="font-title-btn text-sm" @click="setShowImageSet(0)">{{ $t("general.back") }}</div>
                </div>
                <div
                    class="flex flex-row button my-3 text-sm justify-center items-center right-0 bg-silver-dark p-2 rounded-md"
                >
                    <div class="font-title-btn" @click="setShowImageSet(1)">{{ $t("general.forward") }}</div>
                    <inline-svg
                        :src="require('@/assets/icons/caret-right-solid.svg')"
                        alt="Persona següent"
                        class="w-8 h-8 fill-silver-light"
                    />
                </div>
            </div>
            <div class="flex flex-col font-title-btn items-center text-lg text-center gap-2">
                <div class="flex flex-row">
                    <span class="font-cta">{{ imagesSelected.filter((img) => img != 0).length }}</span>
                    /{{ imagesSelected.length }} {{ $t("phase3.selected") }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PhaseHeader from "@/components/PhaseHeader.vue";
import { ref } from "vue";
import { useStore } from "vuex";
import shuffleArray from "@/libs/utils.js";
export default {
    props: {},
    components: { PhaseHeader },

    setup(props) {
        const store = useStore();
        const imagesSelected = ref([0, 0]);
        const images = ref([
            { id: 1, set: 0, src: require("@/assets/images/phase_1_A.png") },
            { id: 2, set: 0, src: require("@/assets/images/phase_1_B.png") },
            { id: 3, set: 0, src: require("@/assets/images/phase_1_C.png") },
            { id: 4, set: 0, src: require("@/assets/images/phase_1_D.png") },
            { id: 5, set: 0, src: require("@/assets/images/phase_1_E.png") },
            { id: 6, set: 0, src: require("@/assets/images/phase_1_F.png") },
            { id: 1, set: 1, src: require("@/assets/images/phase_2_A.png") },
            { id: 2, set: 1, src: require("@/assets/images/phase_2_B.png") },
            { id: 3, set: 1, src: require("@/assets/images/phase_2_C.png") },
            { id: 4, set: 1, src: require("@/assets/images/phase_2_D.png") },
            { id: 5, set: 1, src: require("@/assets/images/phase_2_E.png") },
            { id: 6, set: 1, src: require("@/assets/images/phase_2_F.png") },
        ]);
        shuffleArray(images.value);
        const selectImage = (id, set) => {
            imagesSelected.value[set] = id;
            store.commit("phase3SetResults", { images: imagesSelected });
        };
        const showImageSet = ref(0);
        const setShowImageSet = (action) => {
            if (action) {
                if (showImageSet.value == imagesSelected.value.length - 1) {
                    showImageSet.value = 0;
                } else {
                    showImageSet.value++;
                }
            } else {
                if (showImageSet.value == 0) {
                    showImageSet.value = imagesSelected.value.length - 1;
                } else {
                    showImageSet.value--;
                }
            }
        };

        return {
            images,
            selectImage,
            imagesSelected,
            setShowImageSet,
            showImageSet,
        };
    },
};
</script>

<style lang="scss" scoped>
.suspect-highlight {
    @apply border-4 border-orange-300 border-solid;
}
.suspect {
    filter: brightness(50%);
}
</style>

<template>
    <div class="text-center justify-center items-center pb-2">
        <div class="text-left text-xl items-start justify-start">
            <PhaseHeader :title="$t('phase2.title')" :description="$t('phase2.description')" />
            <UserCards
                :name="person.name"
                :id="person.id"
                :total="persons.length"
                :showId="showIdPerson"
                v-for="person in persons"
                :key="person"
                :changeView="setShowIdPerson"
            >
                <!--<div class="grid grid-cols-2 text-left w-full mb-4">
                    <div class="flex flex-col">
                        <div class="flex font-phase-description">EDAT:</div>
                        <div class="flex font-content ml-1">{{ person.age }} anys</div>
                    </div>
                    <div class="flex flex-col w-full">
                        <div class="flex font-phase-description">SEXE:</div>
                        <div class="flex font-content ml-1">{{ person.gender }}</div>
                    </div>
                </div>-->
                <div class="flex flex-col text-left w-11/12">
                    <div class="flex font-phase-description">{{ $t("phase2.t_description") }}:</div>
                    <div class="flex font-content mx-1">
                        {{ person.description }}
                    </div>
                </div>
                <div class="flex flex-col text-left w-full">
                    <div class="flex font-phase-description mt-4">{{ $t("phase2.case") }}</div>
                    <div class="flex font-phase-description">
                        <select
                            class="w-full mr-8 mb-2 rounded-md h-8 px-2 text-xl font-input"
                            name="case"
                            id="select-case"
                            :value="person.case_id"
                            @change="setCaseId($event, person.id)"
                        >
                            <option v-for="case_obj in cases" :key="case_obj" :value="case_obj.id">
                                {{ case_obj.name }}
                            </option>
                        </select>
                    </div>
                </div>
            </UserCards>
        </div>
    </div>
</template>

<script>
import PhaseHeader from "@/components/PhaseHeader.vue";
import UserCards from "@/components/cards/UserCards.vue";
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import shuffleArray from "@/libs/utils.js";
export default {
    props: {},
    components: { UserCards, PhaseHeader },

    setup(props) {
        const store = useStore();
        const showIdPerson = ref(1);
        const { t } = useI18n();
        const persons = ref([
            {
                id: 1,
                name: t("phase2.window_title"),
                age: 30,
                gender: "Masculi",
                case_id: store.state.storePhasesData.phase2Data.answers[1]
                    ? store.state.storePhasesData.phase2Data.answers[1]
                    : 0,
                description: t("phase2.case_1_description"),
            },
            {
                id: 2,
                name: t("phase2.window_title"),
                age: 35,
                gender: "Masculi",
                case_id: store.state.storePhasesData.phase2Data.answers[2]
                    ? store.state.storePhasesData.phase2Data.answers[2]
                    : 0,
                description: t("phase2.case_2_description"),
            },
            {
                id: 3,
                name: t("phase2.window_title"),
                age: 38,
                gender: "Femeni",
                case_id: store.state.storePhasesData.phase2Data.answers[3]
                    ? store.state.storePhasesData.phase2Data.answers[3]
                    : 0,
                description: t("phase2.case_3_description"),
            },
            {
                id: 4,
                name: t("phase2.window_title"),
                age: 25,
                gender: "Masculi",
                case_id: store.state.storePhasesData.phase2Data.answers[4]
                    ? store.state.storePhasesData.phase2Data.answers[4]
                    : 0,
                description: t("phase2.case_4_description"),
            },
            {
                id: 5,
                name: t("phase2.window_title"),
                age: 40,
                gender: "Femeni",
                case_id: store.state.storePhasesData.phase2Data.answers[5]
                    ? store.state.storePhasesData.phase2Data.answers[5]
                    : 0,
                description: t("phase2.case_5_description"),
            },
            {
                id: 6,
                name: t("phase2.window_title"),
                age: 90,
                gender: "Femeni",
                case_id: store.state.storePhasesData.phase2Data.answers[6]
                    ? store.state.storePhasesData.phase2Data.answers[6]
                    : 0,
                description: t("phase2.case_6_description"),
            },
            {
                id: 7,
                name: t("phase2.window_title"),
                age: 65,
                gender: "Masculi",
                case_id: store.state.storePhasesData.phase2Data.answers[7]
                    ? store.state.storePhasesData.phase2Data.answers[7]
                    : 0,
                description: t("phase2.case_7_description"),
            },
        ]);
        const cases = [
            { id: 1, name: "IAIA ANITA" },
            { id: 2, name: "ANGI" },
            { id: 3, name: "ISIDRE" },
            { id: 4, name: "MACHALA" },
            { id: 5, name: "SONIA" },
            { id: 6, name: "LA FLOR" },
            { id: 7, name: "CA N'AMAT" },
        ];
        shuffleArray(cases);
        const setShowIdPerson = (action) => {
            if (action) {
                if (showIdPerson.value == persons.value.length) {
                    showIdPerson.value = 1;
                } else {
                    showIdPerson.value++;
                }
            } else {
                if (showIdPerson.value == 1) {
                    showIdPerson.value = persons.value.length;
                } else {
                    showIdPerson.value--;
                }
            }
        };

        onMounted(() => {
            persons.value.forEach((element) => {
                store.commit("phase2SetResults", { person_id: element.id, case_id: String(cases[0].id) });
            });
        });

        const setCaseId = (event, person_id) => {
            const case_id = event.target.value;
            const person_index = persons.value.findIndex((obj) => obj.id == person_id);
            persons.value[person_index].case_id = case_id;
            store.commit("phase2SetResults", { person_id: person_id, case_id: case_id });
        };

        return { cases, persons, showIdPerson, setShowIdPerson, setCaseId };
    },
};
</script>

<style lang="scss" scoped>
select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-image: url("@/assets/icons/sort-down-solid.svg");
    background-repeat: no-repeat, repeat;
    background-position: right 0.7em top 0, 0 0;
    background-size: 0.65em auto, 100%;
    @apply bg-silver-light;
}
</style>
